import {
  Snackbar,
  // IconButton
} from '@mui/material';
import useStore from '../services/hooks/useStore';
// import CloseIcon from '@material-ui/icons/Close';
import { observer } from 'mobx-react-lite';
import { Alert } from '@mui/lab';


const Notificacao: React.FC = () => {
  const store = useStore();

  function handleClose(): void {
    store.notificar(store.nivelAlerta, '');
  }

  return (
    <Snackbar
      open={store.notificacao ? true : false}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      key={'bottom, center'}
      style={{ zIndex: 99999999999 }}
      // action={
      //   <>
      //     <IconButton size="small" color="inherit" onClick={handleClose}>
      //       <CloseIcon fontSize="small" />
      //     </IconButton>
      //   </>
      // }
    >
      <Alert variant="filled" onClose={handleClose} severity={store.nivelAlerta}>
        {store.notificacao}
      </Alert>
    </Snackbar>
  );
};

export default observer(Notificacao);
