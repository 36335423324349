import { Fab } from '@mui/material';
import styled from 'styled-components';

type FabProps = {
  $upside?: boolean;
  cor?: string;
};
export const FloatingButton = styled(Fab)<FabProps>`
  position: absolute !important;
  bottom: ${(props) => (props.$upside ? '85px' : '25px')} !important;
  right: 15px !important;

  background-color: ${(props) => props.cor} !important;
  color: white !important;
`;

export const PopupButton = styled.button`
  background: white;
  border: 2px solid #f6f7ff;
  box-sizing: border-box;
  border-radius: 100px;

  width: 100%;
  height: 100%;

  cursor: pointer;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;

  color: #00557f;

  &:focus {
    outline: none;
  }
`;
