import Backdrop from '@mui/material/Backdrop';
import { ModalBox, Modal, ModalTitle, Link } from './styles';
import {
  Button,
  Fade,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Form, Formik } from 'formik';
import ReactInputMask from 'react-input-mask';
import CloseIcon from '@mui/icons-material/Close';
import Validator from '../../services/Validator';
import Formatter from '../../services/Formatter';
import { entrar } from '../../services/login';
import { useState } from 'react';
import useStore from '../../services/hooks/useStore';

const Login: React.FC<ModalProps> = (props) => {
  const store = useStore();
  const [mostrarSenha, setMostrarSenha] = useState(false);

  function handleMostrarSenha() {
    setMostrarSenha((prev) => !prev);
  }

  async function handleLogin(values: { username: string; password: string }) {
    store.toggleLoader();

    await entrar(values)
      .then(() => {
        props.handleClose();
      })
      .catch((err) => {
        console.log(err);
      });

    store.toggleLoader();
  }

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <ModalBox>
          <IconButton onClick={props.handleClose} className="closeBtn">
            <CloseIcon />
          </IconButton>
          <ModalTitle>Acesse a plataforma de integração</ModalTitle>
          <br />
          <Formik
            initialValues={{
              username: '',
              password: '',
            }}
            validate={(values) => {
              const errors: { [key: string]: string } = {};

              if (!Validator.validarCadastro(values.username)) {
                errors.username = 'Insira um CPF válido';
              } else if (values.password.length < 8) {
                errors.password = 'A senha deve conter, no mínimo, 8 caracteres';
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(async () => {
                await handleLogin(values);
                setSubmitting(false);
              }, 400);
            }}
          >
            {({ errors, touched, handleChange, handleBlur, isSubmitting }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ReactInputMask
                      onChange={(e) => {
                        e.target.value = Formatter.getNumeros(e.target.value);
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      mask={'999.999.999-99'}
                      maskChar=""
                    >
                      {/* @ts-ignore */}
                      {() => (
                        <TextField
                          name="username"
                          error={touched.username && !!errors.username}
                          helperText={touched.username && (errors.username || '')}
                          fullWidth
                          variant="outlined"
                          label="CPF"
                        />
                      )}
                    </ReactInputMask>
                  </Grid>

                  <br />
                  <Grid item xs={12}>
                    <FormControl error={touched.password && !!errors.password} fullWidth variant="outlined">
                      <InputLabel htmlFor="password">Senha</InputLabel>
                      <OutlinedInput
                        id="password"
                        name="password"
                        type={mostrarSenha ? 'text' : 'password'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={handleMostrarSenha} onMouseDown={(e) => e.preventDefault()} edge="end">
                              {mostrarSenha ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />

                      <FormHelperText>{touched.password && (errors.password || '')}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>

                <div
                  style={{
                    margin: 10,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: ' center',
                  }}
                >
                  <Link onClick={props.handleClose} to="/termos">
                    Termos de Uso
                  </Link>
                </div>

                <Button fullWidth color="primary" variant="contained" disabled={isSubmitting} type="submit">
                  Entrar
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBox>
      </Fade>
    </Modal>
  );
};

export default Login;
