import { useEffect } from 'react';
import Mapa from '../../components/Mapa';
import { FloatingButton } from '../../components/Botao';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';
import useStore from '../../services/hooks/useStore';
import { observer } from 'mobx-react-lite';

const Home: React.FC = () => {
  const store = useStore();

  useEffect(() => {
    store.setPagina('Mapa de Fazendas');
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Mapa />
      <FloatingButton $upside cor="#FF0000">
        <YouTubeIcon />
      </FloatingButton>
      <FloatingButton cor="#75B73B">
        <WhatsAppIcon />
      </FloatingButton>
    </>
  );
};
export default observer(Home);
