import { useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { Collapse, Tooltip } from '@mui/material';
import useStore from '../../services/hooks/useStore';

// Styles
import { VerticalBar, Lista, ListaItem, ListaTexto, DrawerShadow, ListaDropdown } from './styles';

// Icons
import MapIcon from '@mui/icons-material/Map';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BallotIcon from '@mui/icons-material/Ballot';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BuildIcon from '@mui/icons-material/Build';


const Drawer: React.FC = () => {
  const store = useStore();

  const state = useLocalObservable(() => ({
    collapses: [false, false, false, false, false],
    activeItem: 0,
    paginas: {    
      0: ['Mapa de Fazendas'],
      1: ['Previsões de Despesca', 'Agenda de Despesca'],
      2: ['Romaneios'],
      3: ['Propriedades', 'Listagem de Importação de Lotes e Tanques','Parâmetros de Fechamento de Despesca', 'Categorias'],
      4: ['Pagamentos', 'Recolhimentos'],
      5: ['Calendário de Previsões', 'Listagem de Despescas'],
      6: ['Configurações'],      
    } as { [key: number]: string[] },

   

    setCollapses(value: boolean[]) {
      this.collapses = value;
    },

    toggleCollapse(index: number) {
      this.collapses[index] = !this.collapses[index];
    }
  }));

  function checkPagina(pagina: number): boolean {
    return state.paginas[pagina].some((title) => title === store.pagina);
  }

  function checkSubPagina(subPagina: string): boolean {
    return store.pagina === subPagina;
  }

  function handleChangeAtiveDropdown(index: number): void {
    if (!store.drawer) {
      store.toggleDrawer();
    }
    
    state.toggleCollapse(index);
  }

  const privateLink = (path: string): string => (store.logado ? path : '/');

  useEffect(() => {
    if (!store.drawer) {
      state.setCollapses(Array(state.collapses.length).fill(false));
    }

  }, [store.drawer]);


  return (
    <>
      <VerticalBar onPointerEnter={store.toggleOnDrawer} onPointerLeave={store.toggleOnDrawer} state={store.drawer}>
        <Lista>
          {/* Menu - Mapa de Propriedades */}
          <Tooltip arrow placement="right" title="Mapa de Propriedades">
            <ListaItem $activeLink={checkPagina(0)} onClick={store.checkClick} to="/">
              <MapIcon />
              <ListaTexto>Mapa de Propriedades</ListaTexto>
            </ListaItem>
          </Tooltip>

          {/* Menu - Controle de Despesca */}
          <Tooltip arrow placement="right" title="Controle de Despesca">
            <ListaDropdown $activeLink={checkPagina(1)} onClick={() => handleChangeAtiveDropdown(0)}>
              <LocalShippingIcon />
              <ListaTexto>Controle Despesca</ListaTexto>
            </ListaDropdown>
          </Tooltip>
          <Collapse in={state.collapses[0]} timeout="auto" unmountOnExit>
            <Lista>
              <ListaItem
                $activeLink={checkSubPagina(state.paginas[1][0])}
                onClick={store.toggleDrawer}
                to={privateLink('/previsoes')}
              >
                <FiberManualRecordIcon />
                <ListaTexto>Previsões de Despesca</ListaTexto>
              </ListaItem>
              <ListaItem
                $activeLink={checkSubPagina(state.paginas[1][1])}
                onClick={store.toggleDrawer}
                to={privateLink('/agenda')}
              >
                <FiberManualRecordIcon />
                <ListaTexto>Agenda de Despesca</ListaTexto>
              </ListaItem>
            </Lista>
          </Collapse>

          {/* Menu - Romaneios */}
          <Tooltip arrow placement="right" title="Romaneios">
            <ListaItem $activeLink={checkPagina(2)} onClick={store.checkClick} to="/romaneios">
              <BallotIcon />
              <ListaTexto>Romaneios</ListaTexto>
            </ListaItem>
          </Tooltip>
          
          {/* Menu - Cadastros */}
          <Tooltip arrow placement="right" title="Cadastros">
            <ListaDropdown $activeLink={checkPagina(3)} onClick={() => handleChangeAtiveDropdown(2)}>
              <AssignmentIcon />
              <ListaTexto>Cadastros</ListaTexto>
            </ListaDropdown>
          </Tooltip>
          <Collapse in={state.collapses[2]} timeout="auto" unmountOnExit>
            <Lista>
              <ListaItem
                $activeLink={checkSubPagina(state.paginas[3][0])}
                onClick={store.toggleDrawer}
                to={privateLink('/propriedades')}
              >
                <FiberManualRecordIcon />
                <ListaTexto>Produtor/Propriedade</ListaTexto>
              </ListaItem>
              <ListaItem
                $activeLink={store.pagina === 'Listagem de Importação de Lotes e Tanques'}
                onClick={store.toggleDrawer}
                to={privateLink('/integracao/false')}
              >
                <FiberManualRecordIcon />
                <ListaTexto>Integração Lotes e Tanques</ListaTexto>
              </ListaItem>
              <ListaItem
                $activeLink={checkSubPagina(state.paginas[3][2])}
                onClick={store.toggleDrawer}
                to={privateLink('/parametros-despesca')}
              >
                <FiberManualRecordIcon />
                <ListaTexto>Parâmetros de Despesca</ListaTexto>
              </ListaItem>
              <ListaItem
                $activeLink={checkSubPagina(state.paginas[3][3])}
                onClick={store.toggleDrawer}
                to={privateLink('/categorias')}
              >
                <FiberManualRecordIcon />
                <ListaTexto>Categorias</ListaTexto>
              </ListaItem>
            </Lista>
          </Collapse>

          {/* Menu - Controle de Pagamentos */}
          <Tooltip arrow placement="right" title="Controle de Pagamentos">
            <ListaDropdown $activeLink={checkPagina(4)} onClick={() => handleChangeAtiveDropdown(3)}>
              <AttachMoneyIcon />
              <ListaTexto>Controle de Pagamentos</ListaTexto>
            </ListaDropdown>
          </Tooltip>
          <Collapse in={state.collapses[3]} timeout="auto" unmountOnExit>
            <Lista>
              <ListaItem
                $activeLink={checkSubPagina(state.paginas[4][0])}
                onClick={store.toggleDrawer}
                to={privateLink('/pagamentos')}
              >
                <FiberManualRecordIcon />
                <ListaTexto>Pagamentos</ListaTexto>
              </ListaItem>
              <ListaItem
                $activeLink={checkSubPagina(state.paginas[4][1])}
                onClick={store.toggleDrawer}
                to={privateLink('/recolhimentos')}
              >
                <FiberManualRecordIcon />
                <ListaTexto>Recolhimentos</ListaTexto>
              </ListaItem>
            </Lista>
          </Collapse>

          {/* Menu - Relatórios */}
          <Tooltip arrow placement="right" title="Relatórios">
            <ListaDropdown $activeLink={checkPagina(5)} onClick={() => handleChangeAtiveDropdown(4)}>
              <AssessmentIcon />
              <ListaTexto>Relatórios</ListaTexto>
            </ListaDropdown>
          </Tooltip>
          <Collapse in={state.collapses[4]} timeout="auto" unmountOnExit>
            <Lista>
              <ListaItem
                $activeLink={checkSubPagina(state.paginas[5][0])}
                onClick={store.toggleDrawer}
                to={privateLink('/relatorios/mapa-despescas')}
              >
                <FiberManualRecordIcon />
                <ListaTexto>Calendário de Previsões</ListaTexto>
              </ListaItem>

              <ListaItem
                $activeLink={checkSubPagina(state.paginas[5][1])}
                onClick={store.toggleDrawer}
                to={privateLink('/relatorios/despesca-por-previsao')}
              >
                <FiberManualRecordIcon />
                <ListaTexto>Listagem de Despescas</ListaTexto>
              </ListaItem>
            </Lista>
          </Collapse>

          {/* Menu - Configurações */}
          <Tooltip arrow placement="right" title="Configurações">
            <ListaItem $activeLink={checkPagina(6)} onClick={store.checkClick} to={privateLink('/configuracoes')}>
              <BuildIcon />
              <ListaTexto>Configurações</ListaTexto>
            </ListaItem>
          </Tooltip>
        </Lista>
      </VerticalBar>
      <DrawerShadow state={store.drawer} onClick={store.checkClick} />
    </>
  );
};

export default observer(Drawer);
