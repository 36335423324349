import 'dayjs/locale/pt-br';
import React, { lazy, Suspense, useEffect } from 'react';
import GlobalStyles from './styles/global';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme';
import { BrowserRouter as Router } from 'react-router-dom';
import Notificacao from './components/Notificacao';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import checkUser from './middlewares/checkUser';
import Loader from './components/Loader';
import { ThemeProvider as MaterialThemeProvider } from '@mui/material';
import checkMobile from './middlewares/checkMobile';
import DesktopRoutes from './routes/desktop.routes';
import ReactGa from 'react-ga';

import { useClearCache } from 'react-clear-cache';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
dayjs.extend(utc);
dayjs.locale('pt-BR');

const App: React.FC = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, [isLatestVersion, emptyCacheStorage]);

  useEffect(() => {
    checkUser();
    ReactGa.initialize('UA-148724062-3');
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  const Routes = () => {
    if (checkMobile()) {
      const MobileRoutes = lazy(() => import('./routes/mobile.routes'));

      return (
        <Suspense fallback={<Loader />}>
          <MobileRoutes />
        </Suspense>
      );
    } else {
      return <DesktopRoutes />;
    }
  };

  return (
    <LocalizationProvider  dateAdapter={AdapterDayjs} adapterLocale='pt-BR'>
      <Router>
        <GlobalStyles />
        <MaterialThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <Routes />
            <Notificacao />
            <Loader />
          </ThemeProvider>
        </MaterialThemeProvider>
      </Router>
    </LocalizationProvider >
  );
};

export default App;
