import { createTheme  } from '@mui/material';

export const theme = createTheme ({
  palette: {
    primary: {
      main: '#00557F',
    },
    secondary: {
      main: '#E51A1A',
    },
  },

  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: '#00557F', // A nova maneira de definir estilos
        },
      },
    },
  },
});
