import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ModalHook } from '../../services/hooks/useModal';
import { logout } from '../../services/API';
import useStore from '../../services/hooks/useStore';

// Styles
import { Bar, RightAlign, LeftAlign, Title, LoginButton, RightAlignLogged } from './styles';

// Icons
import MenuIcon from '@mui/icons-material/Menu';

// Types
type Props = {
  modalLogin: ModalHook;
  modalCadastro: ModalHook;
};


const NavBar: React.FC<Props> = ({ modalLogin, modalCadastro }) => {
  const store = useStore();
  
  return (
    <Bar>
      <LeftAlign>
        <IconButton onClick={store.toggleDrawer} edge="start" color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Title>{store.pagina && store.pagina}</Title>
      </LeftAlign>

        {!store.logado ? (
          <RightAlign>
            <>
              <Button variant="outlined" color="inherit" onClick={modalCadastro.open} id="registerBtn">
                Cadastre-se
              </Button>
              <LoginButton variant="contained" onClick={modalLogin.open} id="loginBtn">
                Entrar
              </LoginButton>
            </>
          </RightAlign>
        ) : (
          <RightAlignLogged>          
            <>
              <>
                <Title style={{ display: 'flex', alignItems: 'center' }}>
                  {store.usuario?.frigorificos[0].nomeFantasia}
                </Title>
              </>

              <>
                <Button style={{ width: '10px' }} variant="outlined" color="inherit" onClick={logout} id="logoutBtn">
                  Sair
                </Button>
              </>
            </>
          </RightAlignLogged>
        )}
    </Bar>
  );
};

export default observer(NavBar);