import Axios from 'axios';
import { stateContext } from './hooks/useStore';

// URL padrão para chamadas
export const api = Axios.create({
  baseURL: process.env.REACT_APP_HOST + '/api/',
  headers: {
    'Content-Type': 'application/json',
  },
});

export function setToken(data: { access: string; refresh: string }): void {
  const expiration = new Date();
  expiration.setDate(expiration.getDate() + 30);

  localStorage.setItem('userToken', data.access);
  localStorage.setItem('userRefreshToken', data.refresh);
  localStorage.setItem('userTokenExpiration', expiration.toUTCString());
}

export function logout(): void {
  localStorage.removeItem('userToken');
  localStorage.removeItem('userRefreshToken');
  localStorage.removeItem('userTokenExpiration');

  window.location.href = '/';
}

async function refreshToken(): Promise<void> {
  const userRefreshToken = localStorage.getItem('userRefreshToken');
  await api
    .post('/token/refresh/', {
      refresh: userRefreshToken,
    })
    .then(async (res) => {
      setToken(res.data);
    })
    .catch(() => {
      stateContext.state.notificar('error', 'Ocorreu um erro ao atualizar credenciais.');
      setTimeout(() => {
        logout();
      }, 3000);
    });
}

export async function getCSRFToken(): Promise<void> {
  await api.get('/token/csrf/').then((csrfRes) => {
    api.defaults.headers.post['X-CSRFToken'] = csrfRes.data.csrfToken;
  });
}

export function initInterceptors(): void {
  api.interceptors.request.use(
    async (config) => {
      const userToken = localStorage.getItem('userToken');
      config.headers.Authorization = `Bearer ${userToken}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const access_token = await refreshToken();
        api.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        return api(originalRequest);
      }
      return Promise.reject(error);
    },
  );
}
