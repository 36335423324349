import Backdrop from '@mui/material/Backdrop';
import { ModalBox, Modal, ModalTitle } from './styles';
import {
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';
import ReactInputMask from 'react-input-mask';
import { Observer, observer, useLocalObservable } from 'mobx-react-lite';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Form, Formik } from 'formik';
import { CadastroIntegrador } from './Cadastro';
import CloseIcon from '@mui/icons-material/Close';
import Validator from '../../services/Validator';
import Formatter from '../../services/Formatter';

type Props = ModalProps & {
  state: CadastroIntegrador;
  onConfirm(values: CadastroIntegrador): void;
};
const FinalizarCadastro: React.FC<Props> = (props) => {
  const state = useLocalObservable(() => ({
    mostrarSenha: false,
  }));

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <ModalBox>
          <IconButton onClick={props.handleClose} className="closeBtn">
            <CloseIcon />
          </IconButton>
          <ModalTitle>Faça o cadastro do responsável</ModalTitle>
          <br />
          <Formik
            initialValues={{ ...props.state }}
            validate={(values) => {
              const errors: { [key: string]: string } = {};

              if (!values.nomeResp) {
                errors.nomeResp = 'Insira o nome do responsáel';
              } else if (!Validator.validarCadastro(values.cpfResp)) {
                errors.cpfResp = 'Insira um CPF válido';
              } else if (!Validator.validarTelefone(values.whatsappResp)) {
                errors.whatsappResp = 'Insira um telefone válido';
              } else if (!Validator.validarTelefone(values.telefoneResp)) {
                errors.telefoneResp = 'Insira um telefone válido';
              } else if (!Validator.validarEmail(values.emailResp)) {
                errors.emailResp = 'Insira um e-mail válido';
              } else if (values.senha.length < 8) {
                errors.senha = 'A senha deve conter, no mínimo, 8 caracteres';
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                props.onConfirm(values);
                setSubmitting(false);
              }, 400);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="nomeResp"
                      error={touched.nomeResp && !!errors.nomeResp}
                      helperText={touched.nomeResp && (errors.nomeResp || '')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      label="Nome do Responsável"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <ReactInputMask
                      onChange={(e) => {
                        e.target.value = Formatter.getNumeros(e.target.value);
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      mask={'999.999.999-99'}
                      maskChar=""
                    >
                      {/* @ts-ignore */}
                      {() => (
                        <TextField
                          name="cpfResp"
                          error={touched.cpfResp && !!errors.cpfResp}
                          helperText={touched.cpfResp && (errors.cpfResp || '')}
                          fullWidth
                          variant="outlined"
                          label="CPF"
                        />
                      )}
                    </ReactInputMask>
                  </Grid>

                  <Grid item xs={12}>
                    <ReactInputMask
                      onChange={(e) => {
                        e.target.value = Formatter.getNumeros(e.target.value);
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      mask={'(99) 9 9999-9999'}
                      maskChar=""
                    >
                      {/* @ts-ignore */}
                      {() => (
                        <TextField
                          name="telefoneResp"
                          error={touched.telefoneResp && !!errors.telefoneResp}
                          helperText={touched.telefoneResp && (errors.telefoneResp || '')}
                          fullWidth
                          variant="outlined"
                          label="Telefone Fixo"
                        />
                      )}
                    </ReactInputMask>
                  </Grid>

                  <Grid item xs={12}>
                    <ReactInputMask
                      onChange={(e) => {
                        e.target.value = Formatter.getNumeros(e.target.value);
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      mask={'(99) 9 9999-9999'}
                      maskChar=""
                    >
                      {/* @ts-ignore */}
                      {() => (
                        <TextField
                          name="whatsappResp"
                          error={touched.whatsappResp && !!errors.whatsappResp}
                          helperText={touched.whatsappResp && (errors.whatsappResp || '')}
                          fullWidth
                          variant="outlined"
                          label="WhatsApp"
                        />
                      )}
                    </ReactInputMask>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="emailResp"
                      error={touched.emailResp && !!errors.emailResp}
                      helperText={touched.emailResp && (errors.emailResp || '')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      variant="outlined"
                      label="E-mail"
                      type="email"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Observer>
                      {() => (
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="password">Senha</InputLabel>
                          <OutlinedInput
                            id="password"
                            name="senha"
                            error={touched.senha && !!errors.senha}
                            type={state.mostrarSenha ? 'text' : 'password'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => (state.mostrarSenha = !state.mostrarSenha)}
                                  onMouseDown={(e) => e.preventDefault()}
                                  edge="end"
                                >
                                  {state.mostrarSenha ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <FormHelperText>{touched.senha && (errors.senha || '')}</FormHelperText>
                        </FormControl>
                      )}
                    </Observer>
                  </Grid>
                </Grid>

                <FormControlLabel
                  control={<Checkbox onChange={handleChange} name="termos" color="primary" />}
                  label="Eu concordo com as Políticas de Privacidade da Aquabit!"
                />

                <Button
                  color="primary"
                  variant="contained"
                  data-cy="finish"
                  disabled={!values.termos || isSubmitting}
                  fullWidth
                  type="submit"
                >
                  Finalizar
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBox>
      </Fade>
    </Modal>
  );
};

export default observer(FinalizarCadastro);
