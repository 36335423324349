import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { round } from 'mathjs';

class Formatter {
  /**
   * Função que transforma um número em uma string em formato local
   * @param precision Quantidade de casas decimais
   * @param number Int, float
   * @example
   * // retorna '1.000,50'
   * Formatter.Formatter.formatNumber(2, 1000.50)
   */
  static formatNumber(precision: number, number?: number | string): string {
    if (typeof number === 'number') {
      return round(number, precision).toLocaleString('pt-BR', {
        minimumFractionDigits: precision,
      });
    } else if (typeof number === 'string') {
      return round(parseFloat(number), precision).toLocaleString('pt-BR', {
        minimumFractionDigits: precision,
      });
    } else return '';
  }

  /**
   * Retorna o dia/mês/ano
   * @param data Ex: "00/00/0000 00:00:00"
   */
  static getData(data: string): string {
    return data.split(' ')[0];
  }

  /**
   * Retorna o ano/mês/dia a partir de um objeto data
   * @param data Date object
   */
  static dateToString(date: MaterialUiPickersDate, char?: string): string {
    if (char) {
      return (
        date!.getFullYear() + char + ('0' + (date!.getMonth() + 1)).slice(-2) + char + ('0' + date!.getDate()).slice(-2)
      );
    }
    return (
      date!.getFullYear() +
      '-' +
      ('0' + (date!.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date!.getDate()).slice(-2) +
      ' ' +
      ('0' + date!.getHours()).slice(-2) +
      ':' +
      ('0' + date!.getMinutes()).slice(-2) +
      ':' +
      ('0' + date!.getSeconds()).slice(-2)
    );
  }

  static formatDate(date: MaterialUiPickersDate, abreviarAno?: boolean): string {
    return (
      ('0' + date!.getDate()).slice(-2) +
      '/' +
      ('0' + (date!.getMonth() + 1)).slice(-2) +
      '/' +
      (abreviarAno ? date!.getFullYear().toString().substr(2, 2) : date!.getFullYear())
    );
  }
  static formatHour(date: MaterialUiPickersDate): string {
    return ('0' + date!.getHours()).slice(-2) + ':' + ('0' + date!.getMinutes()).slice(-2);
  }

  static formatCPFCNPJ(cpf_cnpj: string): string {
    const cpfCNPJ = cpf_cnpj.replace(/[^0-9]/g, '');

    if (cpfCNPJ.length === 11) {
      return cpfCNPJ.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (cpfCNPJ.length === 14) {
      return cpfCNPJ.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
    } else {
      return cpf_cnpj;
    }
  }

  static formatTelefone(telefone: string): string {
    return telefone.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/g, '($1) $2 $3-$4');
  }

  /**
   * Retorna apenas os números de uma string
   * @param maskedValue CPF ou CNPJ com máscara, telefone com máscara, etc
   */
  static getNumeros(maskedValue: string): string {
    return maskedValue.replace(/[^0-9]/g, '');
  }

  /**
   * Retorna um array do tipo SelectRow
   * @param arr Array de objetos
   * @param nameField Campo do objeto o qual deve ser definido no campo `name`
   * @param valueField Campo do objeto o qual deve ser definido no campo `value`
   */
  static getSelectRows(arr: Array<any>, nameField: string, valueField: string): SelectRow[] {
    const options: SelectRow[] = [];

    arr.map((item) => {
      options.push({
        name: item[nameField],
        value: item[valueField],
      });
      return null;
    });

    return options;
  }

  static beautifyString(string: string): string {
    const arr = string.toLowerCase().split('_');

    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i][0].toUpperCase() + arr[i].substr(1);
    }

    return arr.join(' ');
  }

  static formatParamType(type: Parametro['type']): string {
    switch (type) {
      case 'PERCENTAGE':
        return '%';

      case 'KILO':
        return 'kg';

      case 'YES/NO':
        return 'Sim/Não';

      default:
        return '';
    }
  }

  static formatBoolean(bool: boolean): string {
    if (bool) {
      return 'Sim';
    } else return 'Não';
  }

  static getCoordenadas(coords: string): Coordenadas {
    return {
      lat: Number(coords.slice(17).replace(')', '').split(' ')[1]),
      lon: Number(coords.slice(17).replace(')', '').split(' ')[0]),
    };
  }
}

export default Formatter;
