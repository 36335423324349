import Backdrop from '@mui/material/Backdrop';
import { ModalBox, Modal, ModalTitle, Scroll } from './styles';
import {
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useModal from '../../services/hooks/useModal';
import FormProdutor from './FormProdutor';
import { Form, Formik } from 'formik';
import useStore from '../../services/hooks/useStore';
import { api } from '../../services/API';
import { observer } from 'mobx-react-lite';
import CloseIcon from '@mui/icons-material/Close';
import AsyncSearchInput from '../Forms/AsyncSearchInput';
import React, { useState } from 'react';
import Previsao from '../../services/models/Previsao';
import AsyncSearchInputLote from '../Forms/AsyncSearchInputLote';
import Formatter from '../../services/Formatter';
import { createStyles, makeStyles } from '@mui/styles';

type Props = ModalProps & {
  onSuccess(): void;
  previsao: Previsao | null;
};

const useStyles = makeStyles(() =>
  createStyles({
    div: {
      width: 530,
      display: 'flex',
      alignItems: 'center',
      boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.12)',
      marginBottom: '1rem',
      borderRadius: '.4rem',
      transition: 'all 0.4s',
      cursor: 'pointer',
      '&:hover': {
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.4)',
      },
    },
  }),
);

const FormPrevisao: React.FC<Props> = ({ open, handleClose, onSuccess }) => {
  const classes = useStyles();
  const theme = useTheme();

  const modalProdutor = useModal();
  const store = useStore();
  const [tanque, setTanque] = useState<any[]>([]);
  const [checked, setChecked] = useState([] as any);

  const handleCheck = (event: { target: { checked: any; value: any } }) => {
    if (event.target.checked) {
      setChecked((oldChecked: any) => [...oldChecked, ...tanque]);
    } else {
      setChecked([]);
    }
  };

  async function cadastrar(): Promise<void> {
    store.toggleLoader();

    await Promise.all(
      checked.map(async (values: any) => {
        const data = {
          id: values.id,
        };

        await api
          .post('/integration/previsao/', data)
          .then(() => {
            store.notificar('success', 'Previsão cadastrada com sucesso!');
            onSuccess();

            handleClose();
          })
          .catch(() => {
            store.notificar('error', 'Ocorreu ao cadastrar a previsão!');
          });
      }),
    );

    store.toggleLoader();
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <ModalBox style={{ maxWidth: '600px' }}>
          <IconButton onClick={handleClose} className="closeBtn">
            <CloseIcon />
          </IconButton>
          <ModalTitle>Gerar Previsões</ModalTitle>
          <br />
          <Formik
            initialValues={{
              producer: '',
              aquabitId: '',
              lote: '',
              loteId: '',
            }}
            onSubmit={() => {
              setTimeout(() => {
                cadastrar();
              }, 400);
            }}
          >
            {({ values, errors, touched, handleBlur, setFieldValue }) => (
              <Form style={{ width: '100%' }}>
                <Scroll>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <AsyncSearchInput
                        url="/producer/"
                        name="AquabitId"
                        inscricao_federal={store.usuario!.cpfCnpj}
                        integration={true}
                        label="Selecione o Produtor e Propriedade"
                        defaultValue={
                          values.producer
                            ? {
                                name: values.producer,
                                id: values.aquabitId,
                              }
                            : undefined
                        }
                        error={touched.producer && !!errors.producer}
                        helperText={touched.producer ? (errors.producer as string) || '' : undefined}
                        onChange={(_e, value) => {
                          setFieldValue('producer', value!.name);
                          setFieldValue('aquabitId', value!.aquabitId);
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item style={{ alignSelf: 'center' }} xs={2}>
                      <IconButton onClick={modalProdutor.open} style={{ height: 48 }}>
                        <AddIcon />
                      </IconButton>
                    </Grid>

                    <Grid item lg={12}>
                      <AsyncSearchInputLote
                        url={`/lotes/`}
                        name="lote"
                        prop={values.aquabitId}
                        integration={true}
                        label="Selecione o Produtor e Propriedade"
                        disabled={values.aquabitId == ''}
                        lote={true}
                        defaultValue={
                          values.lote
                            ? {
                                codigo: values.lote,
                                id: values.loteId,
                              }
                            : undefined
                        }
                        onChange={(_e, value) => {
                          async function BuscarTanque() {
                            await api
                              .get('/integration/lote-tanques/list/', {
                                params: {
                                  previsao: false,
                                },
                              })
                              .then((res: any) => {
                                const newTanque = res.data.results.filter(
                                  (item: any) => parseInt(item.loteId) === parseInt(value!.id),
                                );
                                setTanque(newTanque);
                              });
                          }
                          BuscarTanque();
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item lg={12}></Grid>
                    {tanque.length > 0 ? (
                      <Grid item lg={12} style={{ overflowY: 'auto', height: '400px' }}>
                        <FormControlLabel
                          labelPlacement="start"
                          control={<Checkbox onChange={(e) => handleCheck(e)} tabIndex={-1} />}
                          label="Marcar todos"
                        />
                        <List style={{ width: '100%', maxWidth: 360 }}>
                          {tanque.map((item: any) => {
                            const selected = checked.some((obj: any) => item.id === obj.id);
                            return (
                              <ListItem
                                key={item.id}
                                className={classes.div}
                                style={{
                                  boxShadow: selected
                                    ? '0px 0px 15px rgba(17, 148, 39, 0.836)'
                                    : '0px 0px 15px rgba(0, 0, 0, 0.12)',
                                }}
                              >
                                <ListItemText
                                  onClick={() => {
                                    setChecked((oldChecked: any) => [...oldChecked, item]);
                                  }}
                                  style={{ width: '300px', fontSize: '5px' }}
                                  primary={`${item.tanqueNome || item.tanqueCodigo} - (${item.especie})`}
                                  secondary={`Data alojamento: ${item.dataAlojamento}`}
                                />
                                <ListItemText
                                  style={{ textAlign: 'right', width: '300px' }}
                                  primary={`Qt: ${Formatter.formatNumber(0, item.quantidade)}`}
                                  secondary={`PM Inicial (Kg): ${Formatter.formatNumber(3, item.pesoMedio / 1000)}`}
                                />
                              </ListItem>
                            );
                          })}
                        </List>
                      </Grid>
                    ) : (
                      <Grid item lg={12} style={{ overflowY: 'auto', height: '400px', textAlign: 'center' }}>
                        <p>Nenhum Tanque Selecionando</p>
                      </Grid>
                    )}
                  </Grid>
                </Scroll>
                <Grid container>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      style={{
                        backgroundColor: theme.palette.primary.main, //TODO: change to variant color = primary,
                        height: 40,
                        color: '#FFF',
                      }}
                      variant="contained"
                    >
                      GERAR PREVISÕES
                    </Button>
                  </Grid>
                </Grid>

                {modalProdutor.state ? (
                  <FormProdutor
                    open={modalProdutor.state}
                    handleClose={modalProdutor.close}
                    onConfirm={(fazenda) => {
                      setTimeout(() => {
                        setFieldValue('fazenda', fazenda.id);
                      }, 1000);
                    }}
                  />
                ) : null}
              </Form>
            )}
          </Formik>
        </ModalBox>
      </Fade>
    </Modal>
  );
};

export default observer(FormPrevisao);
