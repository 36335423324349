import { Button } from '@mui/material';
import styled from 'styled-components';

export const Bar = styled.div`
  position: fixed;
  background-color: ${(props) => props.theme.palette.primary.main};

  width: 100vw;
  height: 55px;
  padding: 0 24px;

  color: white;

  display: flex;
  justify-content: space-between;
  align-items: center;

  z-index: 1001;
`;

export const RightAlign = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

export const RightAlignLogged = styled.div`
  display: grid;
  grid-template-columns: 1fr 0fr;
  gap: 20px;
`;

export const LeftAlign = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-right: 20px;
  }
`;

export const Title = styled.h2`
  color: white;
  font-family: 'Roboto';
  font-weight: bold;
`;

export const LoginButton = styled(Button)`
  &.MuiButton-contained {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: white;
  }
`;
