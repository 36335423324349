import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { Autocomplete, AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { api } from '../../services/API';

interface Value {
  name: string;
  id: string;
  [key: string]: any;
}

interface Props {
  url: string;
  name?: string;
  inscricao_federal?: string;
  prop?: string;
  label?: string;
  value?: Value | null;
  defaultValue?: Value | null;
  filters?: Record<string, any>;

  integration?: boolean;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  placeholder?: string;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  onChange?(
    event: React.ChangeEvent<Record<string, any>>,
    value: Value | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Value> | undefined,
  ): void;
  onBlur?(event: React.FocusEvent<HTMLDivElement>): void;
}

const AsyncSearchInput: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<Value[]>([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  async function handleSearch(search?: string): Promise<void> {
    console.log(props);

    if (props.integration) {
      const response = await api.get(`/integration${props.url}`, {
        params: {
          name: search,
          ...props.filters,
          prop: props.prop,
        },
      });
      setOptions(response.data.results);
    } else {
      const response = await api.get(props.url, {
        params: {
          name: search,
          ...props.filters,
          inscricao_federal: props.inscricao_federal,
          prop: props.prop,
        },
      });
      setOptions(response.data.results);
    }
  }

  return (
    <Autocomplete
      open={open}
      disabled={props.disabled}
      onOpen={() => {
        setOpen(true);
        handleSearch();
      }}
      onClose={() => setOpen(false)}
      onChange={(e: any, value: any, reason: any, details: any) => props.onChange!(e, value, reason, details)}
      onInputChange={(_e: any, value: any) => handleSearch(value)}
      onBlur={props.onBlur}
      isOptionEqualToValue={(option: any, value: any) => option.name === value.name}
      getOptionLabel={(option: any) => option.name}
      options={options}
      loading={loading}
      defaultValue={props.defaultValue}
      value={props.value}
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      noOptionsText="Sem opções"
      openText="Abrir"
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderInput={(params: any) => (
        <TextField
          {...params}
          error={props.error}
          label={props.label}
          name={props.name}
          helperText={props.helperText}
          variant={props.variant || 'outlined'}
          placeholder={props.placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AsyncSearchInput;
