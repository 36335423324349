class Validator {
  static validarEmail(email: string): boolean {
    const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return re.test(email);
  }

  static validarCadastro(val: string): boolean {
    if (val.length === 11) {
      const cpf = val
        .trim()
        .split('')
        .map((char) => Number(char));

      let v1 = 0;
      let v2 = 0;
      let aux = false;

      cpf.map((char, index, arr) => {
        if (arr[index - 1] !== char) {
          aux = true;
        }
        return null;
      });

      if (!aux) {
        return false;
      } else {
        for (let i = 0, p = 10; cpf.length - 2 > i; i++, p--) {
          v1 += cpf[i] * p;
        }

        v1 = (v1 * 10) % 11;

        if (v1 === 10) {
          v1 = 0;
        }

        if (v1 !== cpf[9]) {
          return false;
        }

        for (let i = 0, p = 11; cpf.length - 1 > i; i++, p--) {
          v2 += cpf[i] * p;
        }

        v2 = (v2 * 10) % 11;

        if (v2 === 10) {
          v2 = 0;
        }

        if (v2 !== cpf[10]) {
          return false;
        } else {
          return true;
        }
      }
    } else if (val.length === 14) {
      const cnpj = val
        .trim()
        .split('')
        .map((char) => Number(char));

      let v1 = 0;
      let v2 = 0;
      let aux = false;

      cnpj.map((char, index, arr) => {
        if (arr[index - 1] !== char) {
          aux = true;
        }
        return null;
      });

      if (!aux) {
        return false;
      } else {
        for (let index = 0, p1 = 5, p2 = 13; cnpj.length - 2 > index; index++, p1--, p2--) {
          if (p1 >= 2) {
            v1 += cnpj[index] * p1;
          } else {
            v1 += cnpj[index] * p2;
          }
        }

        v1 = v1 % 11;

        if (v1 < 2) {
          v1 = 0;
        } else {
          v1 = 11 - v1;
        }

        if (v1 !== cnpj[12]) {
          return false;
        }

        for (let i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
          if (p1 >= 2) {
            v2 += cnpj[i] * p1;
          } else {
            v2 += cnpj[i] * p2;
          }
        }

        v2 = v2 % 11;

        if (v2 < 2) {
          v2 = 0;
        } else {
          v2 = 11 - v2;
        }

        if (v2 !== cnpj[13]) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  static validarTelefone(telefone: string): boolean {
    if (telefone.length < 11) {
      return false;
    } else {
      return true;
    }
  }

  static validarCoordenadas(coords: string): boolean {
    const arr = coords.split(',');

    if (Number(arr[0]) && Number(arr[1])) {
      return true;
    } else {
      return false;
    }
  }
}

export default Validator;
