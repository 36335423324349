import Backdrop from '@mui/material/Backdrop';
import { ModalBox, Modal, ModalTitle } from './styles';
import { Button, Fade, FormLabel, Grid, IconButton, } from '@mui/material';
import useModal from '../../services/hooks/useModal';
import FormProdutor from './FormProdutor';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import AsyncSearchInputLote from '../Forms/AsyncSearchInputLote';



type Props = ModalProps & {
  cadastrarTanque: (value: any) => void
};

const FormPrevisao: React.FC<Props> = ({ open, handleClose, cadastrarTanque }) => {
  const modalProdutor = useModal();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}

    >
      <Fade in={open}>
        <ModalBox style={{ textAlign: 'center' }}>
          <IconButton onClick={handleClose} className="closeBtn">
            <CloseIcon />
          </IconButton>
          <ModalTitle>Informe a Safra </ModalTitle>

          <br />
          <Formik
            initialValues={{
              safra: '',
              safraId:'',
            }}
            validate={(values) => {
              const errors: { [key: string]: string } = {};
              if (!values.safra) {
                errors.safra = 'Informe a Safra';
              }
              return errors;
            }}
            onSubmit={(values) => {
              cadastrarTanque(values.safraId)
            }}

          >
            {({ values,handleBlur, setFieldValue }) => (
              <Form style={{ width: '100%' }}>
                <FormLabel component="legend" style={{ textAlign: "start" }}>Informe a safra que esse lote faz parte</FormLabel>

                <Grid item lg={12} style={{margin:"3rem 0"}}>
                      <AsyncSearchInputLote
                        url={`/safras/`}
                        name="safra"
                        prop={values.safra}
                        integration={false}
                        label="Selecione a Safra"
                        onBlur={handleBlur}
                        onChange={(_e, value) => {
                          console.log(value)
                          setFieldValue('safra', value!.name)
                          setFieldValue('safraId', value!.id)

                        }}
                      />
                    </Grid>
                <Button type="submit" fullWidth color="primary" variant="contained"
                >
                  Salvar
                </Button>

                {modalProdutor.state ? (
                  <FormProdutor
                    open={modalProdutor.state}
                    handleClose={modalProdutor.close}
                    onConfirm={(fazenda) => {
                      setTimeout(() => {
                        setFieldValue('fazenda', fazenda.id);
                      }, 1000);
                    }}
                  />
                ) : null}
              </Form>
            )}
          </Formik>
        </ModalBox>
      </Fade>
    </Modal >
  );
};

export default observer(FormPrevisao);
