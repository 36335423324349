import Backdrop from '@mui/material/Backdrop';
import { ModalBox, Modal, ModalTitle, Scroll } from './styles';
import { Button, Fade, Grid, IconButton, TextField, useTheme } from '@mui/material';
import ReactInputMask from 'react-input-mask';
import InputEndereco from '../Forms/InputEndereco';
import CPFCNPJInput from '../Forms/CPFCNPJInput';
import { Form, Formik } from 'formik';
import useStore from '../../services/hooks/useStore';
import { api } from '../../services/API';
import CloseIcon from '@mui/icons-material/Close';
import { useLocalObservable } from 'mobx-react-lite';
import Validator from '../../services/Validator';
import Formatter from '../../services/Formatter';

interface CadastroFazenda {
  cpfCnpj: string;
  nome: string;
  email: string;
  telefone: string;
  responsavel: string;
  coordenadas: string;
  estado: string;
  cidade: string;
  endereco: string;
  isAquabitUser: boolean;
  aquabitId: string;
}

type Props = ModalProps & {
  fazenda?: Fazenda | null;
  onConfirm?(fazenda: Fazenda): void;
};

const FormProdutor: React.FC<Props> = ({ open, handleClose, onConfirm, fazenda }) => {
  const store = useStore();

  const theme = useTheme();
  const state = useLocalObservable(() => ({
    isAquabit: false,

    setIsAquabit(bool: boolean) {
      this.isAquabit = bool;
    },
  }));

  async function cadastrar(values: CadastroFazenda): Promise<void> {
    store.toggleLoader();

    const coords = {
      lat: Number(values.coordenadas.split(',')[0]),
      lon: Number(values.coordenadas.split(',')[1]),
    };

    const producer = await api
      .post('/producer/', {
        name: values.responsavel,
        cpfCnpj: values.cpfCnpj,
        email: values.email,
        phoneNumber: values.telefone,
        isAquabitUser: values.isAquabitUser,
        // TODO: remover frigorifico
        frigorifico: store.frigorifico.id,
        aquabitId: parseInt(values.aquabitId),
      })
      .catch(() => {
        store.notificar('error', 'Ocorreu um erro ao cadastrar o produtor.');
        return null;
      });

    if (producer) {
      const fazenda = await api
        .post('/fazenda/', {
          frigorifico: store.usuario!.frigorificos[0].id,
          responsavel: producer.data.id,
          name: values.nome,
          coordenadas: `POINT(${coords.lon} ${coords.lat})`,
          estado: values.estado,
          cidade: values.cidade,
          endereco: values.endereco,
          aquabitId: parseInt(values.aquabitId),
        })
        .catch(() => {
          store.notificar('error', 'Ocorreu um erro ao cadastrar a propriedade.');
          return null;
        });

      if (fazenda) {
        store.notificar('success', 'Propriedade/Produtor cadastrado com sucesso!');
        if (onConfirm) onConfirm(fazenda.data as Fazenda);
        handleClose();
      }
    }

    store.toggleLoader();
  }

  async function editar(values: CadastroFazenda): Promise<void> {
    store.toggleLoader();
    const coords = {
      lat: Number(values.coordenadas.split(',')[0]),
      lon: Number(values.coordenadas.split(',')[1]),
    };

    await api
      .patch('/fazenda/' + fazenda!.id + '/', {
        frigorifico: store.usuario!.frigorificos[0].id,
        name: values.nome,
        coordenadas: `POINT(${coords.lon} ${coords.lat})`,
        estado: values.estado || undefined,
        cidade: values.cidade || undefined,
        endereco: values.endereco,
      })
      .then(async (res) => {
        await api.patch('/producer/' + fazenda?.responsavel.id + '/', {
          name: values.responsavel,
          cpfCnpj: values.cpfCnpj,
          email: values.email,
          phoneNumber: values.telefone,
        });
        store.notificar('success', 'Propriedade/Produtor editado com sucesso!');
        if (onConfirm) onConfirm(res.data as Fazenda);
        handleClose();
      })
      .catch(() => {
        store.notificar('error', 'Ocorreu um erro ao editar a propriedade.');
      });

    store.toggleLoader();
  }

  async function checkProdutor(e: React.ChangeEvent<HTMLInputElement>): Promise<AquabitUser | null> {
    const value = e.target.value;
    let result = null;

    if (Validator.validarCadastro(value)) {
      store.toggleLoader();
      await api
        .get('/integration/user_aquabit/', {
          params: {
            inscricao_federal: value,
          },
        })
        .then((res) => {
          if (res.data) {
            state.setIsAquabit(true);
            result = res.data.result;
          } else {
            state.setIsAquabit(false);
          }
        })
        .catch(() => {
          store.notificar('error', 'CPF/CNPJ já cadastrado! ');
        });

      store.toggleLoader();
    }

    return result;
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <ModalBox>
          <IconButton onClick={handleClose} className="closeBtn">
            <CloseIcon />
          </IconButton>
          <ModalTitle>{fazenda ? 'Editar' : 'Novo'} produtor ou propriedade</ModalTitle>
          <br />
          <Formik
            initialValues={{
              cpfCnpj: fazenda?.responsavel.cpfCnpj || '',
              nome: fazenda?.name || '',
              email: fazenda?.responsavel.email || '',
              telefone: fazenda?.responsavel.phoneNumber || '',
              responsavel: fazenda?.responsavel.name || '',
              coordenadas: fazenda?.coordenadas.slice(17).replace(')', '').split(' ').reverse().join(', ') || '',
              estado: '',
              cidade: '',
              endereco: fazenda?.endereco || '',
              isAquabitUser: false,
              aquabitId: '',
            }}
            validate={(values) => {
              const errors: { [key: string]: string } = {};

              if (!Validator.validarCadastro(values.cpfCnpj)) {
                errors.cpfCnpj = 'Insira um CPF/CNPJ válido';
              } else if (!values.nome) {
                errors.nome = 'Informe o nome da propriedade ou produtor';
              }
              // Opcional
              else if (values.email && !Validator.validarEmail(values.email)) {
                errors.email = 'Informe um e-mail válido';
              } else if (!Validator.validarTelefone(values.telefone)) {
                errors.telefone = 'Insira um telefone válido';
              } else if (!values.responsavel) {
                errors.responsavel = 'Informe o nome do responsável';
              } else if (!Validator.validarCoordenadas(values.coordenadas)) {
                errors.coordenadas = "Insira as coordenadas no formato 'LAT, LON'";
              } else if (!values.endereco) {
                errors.endereco = 'Campo obrigatório';
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(async () => {
                if (fazenda) {
                  await editar(values);
                } else {
                  await cadastrar(values);
                }
                setSubmitting(false);
              }, 400);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, isSubmitting, setFieldValue }) => (
              <Form>
                <Scroll>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <CPFCNPJInput
                        value={values.cpfCnpj}
                        name="cpfCnpj"
                        error={touched.cpfCnpj && !!errors.cpfCnpj}
                        helperText={touched.cpfCnpj ? errors.cpfCnpj || '' : undefined}
                        onChange={(e) => {
                          handleChange(e);

                          checkProdutor(e).then((aquabitUser) => {
                            if (aquabitUser) {
                              setFieldValue('aquabitId', aquabitUser.id);
                              setFieldValue('email', aquabitUser.email);
                              setFieldValue('telefone', aquabitUser.telefone);
                              setFieldValue('nome', aquabitUser.nome);
                              setFieldValue('isAquabitUser', true);
                              setFieldValue('aquabitId', aquabitUser.id);
                            }
                          });
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={values.nome}
                        name="nome"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.nome && !!errors.nome}
                        helperText={touched.nome ? errors.nome || '' : undefined}
                        fullWidth
                        variant="outlined"
                        label="Nome do Produtor ou Propriedade"
                        disabled={state.isAquabit}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={values.email}
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.email && !!errors.email}
                        helperText={touched.email ? errors.email || '' : undefined}
                        fullWidth
                        variant="outlined"
                        label="E-mail"
                        disabled={state.isAquabit}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ReactInputMask
                        value={values.telefone}
                        onChange={(e) => {
                          e.target.value = Formatter.getNumeros(e.target.value);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        mask={'(99) 9 9999-9999'}
                        maskChar=""
                        disabled={state.isAquabit}
                      >
                        {/* @ts-ignore */}
                        {() => (
                          <TextField
                            name="telefone"
                            error={touched.telefone && !!errors.telefone}
                            helperText={touched.telefone && (errors.telefone || '')}
                            fullWidth
                            variant="outlined"
                            label="Telefone (WhatsApp)"
                            disabled={state.isAquabit}
                          />
                        )}
                      </ReactInputMask>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={values.responsavel}
                        name="responsavel"
                        error={touched.responsavel && !!errors.responsavel}
                        helperText={touched.responsavel && (errors.responsavel || '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        variant="outlined"
                        label="Responsável"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputEndereco
                        value={values.coordenadas}
                        name="coordenadas"
                        error={touched.coordenadas && !!errors.coordenadas}
                        helperText={touched.coordenadas ? errors.coordenadas || '' : undefined}
                        onBlur={handleBlur}
                        onChange={(coords, cidade, estado) => {
                          setFieldValue('coordenadas', `${coords.lat}, ${coords.lon}`);
                          setFieldValue('cidade', cidade);
                          setFieldValue('estado', estado);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={values.endereco}
                        name="endereco"
                        error={touched.endereco && !!errors.endereco}
                        helperText={touched.endereco && (errors.endereco || '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        variant="outlined"
                        label="Endereço"
                      />
                    </Grid>
                  </Grid>
                </Scroll>
                <Grid container>
                  <Grid item xs={12}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      fullWidth
                      variant="contained"
                      style={{
                        backgroundColor: theme.palette.primary.main, //TODO: change to variant color = primary
                        height: 40,
                        color: '#fff',
                      }}
                    >
                      {fazenda ? 'Salvar' : 'Cadastrar'}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </ModalBox>
      </Fade>
    </Modal>
  );
};

export default FormProdutor;
