import { useEffect } from 'react';
import { GeolocateButton, NavigationButtons, FullscreenButton, FrigoPin } from './styles';
import ReactMapGL, { Marker, ViewState, ViewStateChangeEvent } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MarcadoresFazendas from './Marcadores';
import { Container } from '../Container';
import useStore from '../../services/hooks/useStore';
import { observer, useLocalObservable } from 'mobx-react-lite';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import Formatter from '../../services/Formatter';

const Mapa: React.FC = () => {
  const store = useStore();
  const state = useLocalObservable(() => ({
    mapState: {
      latitude: -24.9637544,
      longitude: -53.4423917,
      zoom: 12,
      bearing: 0,
      pitch: 0,
    },

    updateMapState(viewport: ViewState) {
      this.mapState = { ...viewport };
    },
  }));

  useEffect(() => {
    if (store.logado) {
      const coords = Formatter.getCoordenadas(store.usuario?.frigorificos[0].coordenadas);
      state.updateMapState({
        latitude: coords.lat,
        longitude: coords.lon,
        zoom: 8,
        bearing: 0,
        pitch: 0,
        padding: { top: 0, bottom: 0, left: 1000, right: 0 },
      });
    }
  }, [store.logado]);

  return (
    <Container className="mapa" id="map-container" style={{ width: '100%', height: '100%' }}>
      <ReactMapGL
        {...state.mapState}
        onClick={store.checkClick}
        mapStyle="mapbox://styles/gabspereiradev/cl7saqm3e001a16ma5t0zvmkz"
        mapboxAccessToken={store.mapboxToken}
        onMove={(evt: ViewStateChangeEvent) => {
          state.updateMapState(evt.viewState)
          
        }}
        onLoad={(e: any) => {
          const map = e.target;

          map.setLayoutProperty('country-label', 'text-field', ['get', 'name_pt']);
        }}
      >
        <div style={{ position: 'absolute', right: 10, top: 10, zIndex: 4 }}>
          <NavigationButtons />
          <FullscreenButton containerId="map-container" />
        </div>
        <GeolocateButton
          style={{ zIndex: 4 }}
          positionOptions={{ enableHighAccuracy: true }}
          trackUserLocation={true}
        />

        {store.logado ? (
          <>
            <Marker
              key={Math.random()}
              longitude={Formatter.getCoordenadas(store.usuario?.frigorificos[0].coordenadas).lon}
              latitude={Formatter.getCoordenadas(store.usuario?.frigorificos[0].coordenadas).lat}
            >
              <FrigoPin>
                <PersonPinCircleIcon fontSize="large" />
              </FrigoPin>
            </Marker>

            <MarcadoresFazendas />
          </>
        ) : null}
      </ReactMapGL>
    </Container>
  );
};

export default observer(Mapa);
