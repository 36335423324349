import { stateContext } from '../services/hooks/useStore';
import { api, getCSRFToken, initInterceptors, logout } from '../services/API';

/**
 * Função que checa se o token salvo no `localStorage` é válido.
 */
export default async function checkUser(): Promise<void | null> {
  const store = stateContext.state;

  store.toggleLoader();

  if (store.token) {
    await getCSRFToken()
      .then(async () => {
        initInterceptors();
        await api
          .get('/user/me/')
          .then((res) => {
            store.setLogado(res.data);
          })
          .catch(() => {
            logout();
          });
      })
      .catch(() => {
        logout();
      });
  } else {
    if (window.location.pathname !== '/') {
      window.location.href = '/';
    }
  }

  store.toggleLoader();
}
