import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { Link } from 'react-router-dom';

type Props = {
  state: boolean;
};

export const Lista = styled.div`
  display: flex;
  flex-direction: column;
`;

type ListaItemProps = {
  $activeLink?: boolean;
};
export const ListaItem = styled(Link)<ListaItemProps>`
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  cursor: pointer;
  transition: background 0.8s;
  background-position: center;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  padding: 10px 24px;

  color: ${(props) => props.theme.palette.primary.main};

  svg {
    margin-right: 28px;
    color: #bcbcbc;
    path {
      fill: #bcbcbc;
    }
  }

  ${(props) =>
    props.theme &&
    css`
      &:hover {
        background: ${transparentize(0.95, props.theme.palette.primary.main)}
          radial-gradient(circle, transparent 1%, ${transparentize(0.95, props.theme.palette.primary.main)} 1%)
          center/15000%;
      }
    `}

  ${(props) =>
    props.$activeLink &&
    css`
      border-left: 5px solid ${(props) => props.theme.palette.primary.main};

      svg {
        color: ${(props) => props.theme.palette.primary.main};
        path {
          fill: ${(props) => props.theme.palette.primary.main};
        }
      }
    `}

  &:active {
    background-color: ${transparentize(0.95, 'black')};
    background-size: 100%;
    transition: background 0s;
  }
`;

export const ListaDropdown = styled.div<ListaItemProps>`
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  cursor: pointer;
  transition: background 0.8s;
  background-position: center;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  padding: 10px 24px;

  color: ${(props) => props.theme.palette.primary.main};

  svg {
    margin-right: 28px;
    path {
      fill: #bcbcbc;
    }
  }

  ${(props) =>
    props.theme &&
    css`
      &:hover {
        background: ${transparentize(0.95, props.theme.palette.primary.main)}
          radial-gradient(circle, transparent 1%, ${transparentize(0.95, props.theme.palette.primary.main)} 1%)
          center/15000%;
      }
    `}

  ${(props) =>
    props.$activeLink &&
    css`
      border-left: 5px solid ${(props) => props.theme.palette.primary.main};

      svg path {
        fill: ${(props) => props.theme.palette.primary.main};
      }
    `}

  &:active {
    background-color: ${transparentize(0.95, 'black')};
    background-size: 100%;
    transition: background 0s;
  }
`;

export const ListaTexto = styled.span`
  font-family: 'Raleway';
  font-size: 12px;
  font-weight: bold;

  min-width: 130px;
  transition: 0.05s;
  opacity: 0;
  pointer-events: none !important;
`;

export const VerticalBar = styled.div<Props>`
  background-color: white;
  width: 70px;
  height: calc(100vh - 55px);
  transition: 0.2s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1000;

  ${(props) =>
    props.state &&
    css`
      width: 280px;

      ${ListaTexto} {
        transition: 0.5s;
        transition-timing-function: cubic-bezier(0.57, -0.17, 0.98, 0.51);
        opacity: 1;
        pointer-events: all;
      }
    `}
`;

export const DrawerShadow = styled.div<Props>`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;

  transition: 0.3s;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;

  cursor: pointer;

  z-index: 999;

  ${(props) =>
    props.state &&
    css`
      pointer-events: all;
      opacity: 1;
    `}
`;
