import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Roboto";
  }

  hr {
    width: 90%;
  }

  .mapboxgl-map, .overlays {
    border-radius: 5px;
  }
`;
