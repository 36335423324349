import { makeAutoObservable } from 'mobx';

// Models
import Especies from '../services/models/Especies';
import Situacoes from '../services/models/Situacoes';
import Frigorifico from '../services/models/Frigorifico';
import Categorias from '../services/models/Categorias';
import FormasDePagamento from '../services/models/FormasDePagamento';
import FrigoConfigs from '../services/models/FrigoConfigs';


class State {
  constructor() {
    makeAutoObservable(this);
  }

  pagina = '';

  drawer = false;
  onDrawer = false;

  notificacao = '';
  nivelAlerta: 'success' | 'info' | 'warning' | 'error' = 'success';

  mapboxToken = 'pk.eyJ1IjoiZ2Fic3BlcmVpcmFkZXYiLCJhIjoiY2w3czhvcXI0MGh1ZDN3bXFuNXdsMHl1NyJ9.cQygFlr11pidWaWT5TqTXw';
  token = localStorage.getItem('userToken');
  usuario: Usuario | null = null;

  logado = false;
  frigorifico = new Frigorifico();
  setLogado = (userData: Usuario) => {
    this.logado = true;
    this.usuario = userData;
    this.frigorifico.set(userData.frigorificos[0]);
  };

  // Drawer Actions
  setPagina = (value: string) => {
    this.pagina = value;
  };
  
  toggleDrawer = () => {
    this.drawer = !this.drawer;
  };

  toggleOnDrawer = () => {
    this.onDrawer = !this.onDrawer;
  };

  checkClick = () => {
    if (!this.onDrawer && this.drawer) {
      this.toggleDrawer();
    }
    if (this.onDrawer && this.drawer) {
      this.toggleDrawer();
    }
  };

  notificar = (severity: 'success' | 'info' | 'warning' | 'error', message: string) => {
    this.nivelAlerta = severity;
    this.notificacao = message;
  };

  loader = false;
  toggleLoader = () => {
    this.loader = !this.loader;
  };

  especies = new Especies();
  situacoes = new Situacoes();
  categorias = new Categorias();
  formasDePagamento = new FormasDePagamento();
  configs = new FrigoConfigs();
}

export default State;
