/* eslint-disable @typescript-eslint/no-unused-vars */

import { FormControlLabel, IconButton, InputAdornment, Switch, TextField, Tooltip } from '@mui/material';
import { Skeleton } from '@mui/material';
import MaterialTable, { Column } from 'material-table';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { Container, Content } from '../../components/Container';
import { config } from '../../components/Tabela';
import { Tabela } from '../../components/Tabela/styles';
import { api } from '../../services/API';
import useStore from '../../services/hooks/useStore';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SearchIcon from '@mui/icons-material/Search';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { DebounceInput } from 'react-debounce-input';
import Header from '../../components/Header';
import FormImportacao from '../../components/Modais/FormImportacao';
import useModal from '../../services/hooks/useModal';
import Previsao from '../../services/models/Previsao';
import FiltroAvancado from '../../components/FiltroAvancado';
import Formatter from '../../services/Formatter';
import FormImportacaoPrevisao from '../../components/Modais/FormImportacaoPrevisao';
import { useParams } from 'react-router-dom';
import React from 'react';
import FiltroAvancadoPrevisoesImportados from '../../components/FiltroAvancado/FiltroAvancadoPrevisoesImportados';

const Integracao: React.FC = () => {
  const params: any = useParams()
  const store = useStore();
  const columns: Column<any>[] = [


    {
      field: 'PR',
      title: 'Produtor',
      type: 'string',
      render(data: any) {
        return data.produtor.name
      },
      ...(true && ({ width: 180 } as Record<string, any>)),
    },
    {
      field: 'loteCodigo',
      title: 'Lote',
      type: 'string',
      ...(true && ({ width: 180 } as Record<string, any>)),
    },
    {
      field: 'tanqueNome',
      title: 'Tanque',
      type: 'string',
      render(data: any) {
        return data.tanqueNome ? data.tanqueNome : "Sem Nome"
      },
      ...(true && ({ width: 180 } as Record<string, any>)),
    },
    {
      field: 'safra.name',
      title: 'Safra',
      type: 'string',

      ...(true && ({ width: 180 } as Record<string, any>)),
    },
    {
      field: 'especie',
      title: 'Espécie',
      type: 'string',

      render(data: any) {
        return data.especie ? data.especie : "Sem Espécie"
      },

      ...(true && ({ width: 180 } as Record<string, any>)),
    },
   
    {
      field: 'dataAlojamento',
      title: 'Dt Alojamento',
      type: 'string',
      ...(true && ({ width: 180 } as Record<string, any>)),
    },
    {
      field: 'previsao',
      title: 'Gerar Previsão',
      type: 'string',
      render(data: any) {
        return data.previsao ? "Sim" : "Não"
      },
      ...(true && ({ width: 80 } as Record<string, any>)),
    },
    {
      align: "right",
      field: 'quantidade',
      title: 'Quantidade',
      type: 'string',
      render(data: any) {
        return Formatter.formatNumber(0, data.quantidade)
      },
      ...(true && ({ width: 180 } as Record<string, any>)),
    },
    {
      align: "right",
      field: 'pesoMedio',
      title: 'PM Inicial (Kg)',
      type: 'string',
      render(data: any) {
        return Formatter.formatNumber(3, data.pesoMedio / 1000)
      },
      ...(true && ({ width: 180 } as Record<string, any>)),
    },
  ];
  const tableRef = useRef(null);
  const state = useLocalObservable(() => ({
    pageSize: 0,
    finalizadas:false,
    selectedPropriedade: null as Fazenda | null,
    toggleFinalizadas() {
      this.finalizadas = !this.finalizadas;
      store.toggleLoader();
      (tableRef.current as any).onQueryChange();
      store.toggleLoader();
    },
    setPageSize(value: number) {
      this.pageSize = value + 1;
    },
    produtor:null,
    safra:null,
    lote:null,
    filtros: { finalizadas: false } as Record<string, any>,
    filtrar(filtros: Record<string, any>) {
      console.log(filtros);
      this.filtros = { ...filtros };
      (tableRef.current as any).onQueryChange();
    },

    selectedPrevisao: null as Previsao | null,
    setSelectedPrevisao(previsao: Previsao | null) {
      this.selectedPrevisao = previsao;
    },
  }));
  const modalImportacao = useModal();
  const modalPrevisao = useModal();

  function filtrar(valor: string): void {
    const query = {
      filters: [
        {
          column: {
            field: 'name',
            title: 'Nome Propriedade',
            type: 'string',
            ...(true && ({ width: 180 } as Record<string, any>)),
          },
          operator: '=',
          value: valor,
        },
      ],
    };

    (tableRef.current as any).onQueryChange(query);
  }
  useEffect(() => {
    store.pagina = "Listagem de Importação de Lotes e Tanques"
    if (params.boolean === 'true') {
      state.selectedPropriedade = null;
      modalImportacao.open();
    }


  }, [state])

  return (
    <>
      <Container>
        <Header>
          <div style={{ display: 'grid', gap: 10, gridTemplateColumns: '1fr' }}>
            <DebounceInput
              element={TextField}
              minLength={3}
              debounceTimeout={1000}
              onChange={(e) => filtrar(e.target.value)}
              variant="outlined"
              label="Procurar"
              fullWidth
              autoComplete="off"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div style={{ display: 'grid', gap: 10, gridTemplateColumns: '250px' }}>
            <FormControlLabel
              control={<Switch checked={state.finalizadas} onChange={state.toggleFinalizadas} color="primary" />}
              label="Exibir Finalizadas"
            />
          </div>

          <div>
            <Tooltip arrow placement="right" title="Gerar Previsões">
              <IconButton
                onClick={() => {
                  state.selectedPropriedade = null;
                  modalPrevisao.open();
                }}
              >
                <LocalShippingIcon />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Importar Lotes e Tanques">
              <IconButton
                onClick={() => {
                  state.selectedPropriedade = null;
                  modalImportacao.open();
                }}
              >
                <SaveAltIcon />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="left" title="Filtros">
              <FiltroAvancado onUpdate={state.filtrar} filtros={state.filtros} Content={FiltroAvancadoPrevisoesImportados} />
            </Tooltip>



          </div>
        </Header>
        <Content>
          <Tabela>
            {store.logado ? (
              <MaterialTable
                tableRef={tableRef}
                columns={columns}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    let ordering = query.orderBy?.field as string;
                    if (query.orderDirection === 'desc') {
                      ordering = `-${ordering}`;
                    }

                    api
                      .get('/integration/lote-tanques/list/', {
                        params: {
                          page: query.page + 1,
                          ordering,
                          ...state.filtros,
                        },
                      })
                      .then((res) => {
                        if (res.data.count) {
                          if (res.data.count >= 50) {
                            state.setPageSize(50);
                          } else {
                            state.setPageSize(res.data.count);
                          }
                        } else {
                          state.setPageSize(0);
                        }

                        resolve({
                          data: res.data.results,
                          page: query.page,
                          totalCount: res.data.count,
                        });
                      })
                      .catch((err) => {
                 
                        store.notificar('error', 'Ocorreu um erro ao gerar relatório. Tente novamente mais tarde!');
                        reject();
                      });
                  })
                }
                options={{ ...config.options, pageSize: state.pageSize }}
                localization={config.localization}
                style={config.style}
              />
            ) : (
              <Skeleton variant="rectangular" width="100%" height={300} />
            )}
          </Tabela>
        </Content>
      </Container>
      {modalImportacao.state && (
        <FormImportacao
          open={modalImportacao.state}
          handleClose={modalImportacao.close}
          onSuccess={() => (tableRef.current as any).onQueryChange({ page: 0 })}
          previsao={state.selectedPrevisao}
        />
      )}
      {modalPrevisao.state && (
        <FormImportacaoPrevisao
          open={modalPrevisao.state}
          handleClose={modalPrevisao.close}
          onSuccess={() => (tableRef.current as any).onQueryChange({ page: 0 })}
          previsao={state.selectedPrevisao}
        />
      )}
    </>
  );
};

export default observer(Integracao);
