import Backdrop from '@mui/material/Backdrop';
import { ModalBox, Modal, ModalTitle, Scroll } from './styles';
import { Button, Fade, Grid, IconButton, TextField } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react-lite';
import ReactInputMask from 'react-input-mask';
import Select from '../Forms/Select';
import useModal from '../../services/hooks/useModal';
import FinalizarCadastro from './FinalizarCadastro';
import { Formik, Form } from 'formik';
import InputEndereco from '../Forms/InputEndereco';
import { api, getCSRFToken, initInterceptors, logout, setToken } from '../../services/API';
import useStore from '../../services/hooks/useStore';
import NumericInput from 'material-ui-numeric-input';
import CloseIcon from '@mui/icons-material/Close';
import Validator from '../../services/Validator';
import Formatter from '../../services/Formatter';

export interface CadastroIntegrador {
  cnpj: string;
  razaoSocial: string;
  nomeFantasia: string;
  tipo: string;

  quantidadeAbate: number;
  sistemaIns: string;
  estado: string;
  cidade: string;
  coordenadas: string;

  // Responsável
  nomeResp: string;
  cpfResp: string;
  whatsappResp: string;
  telefoneResp: string;
  emailResp: string;
  senha: string;

  termos: boolean;
}

const Cadastro: React.FC<ModalProps> = (props) => {
  const store = useStore();
  const state = useLocalObservable(() => ({
    cadastro: null as CadastroIntegrador | null,
  }));
  const modalFinalizar = useModal();

  const tipos = [
    { name: 'Frigorífico', value: 'Frigorífico' },
    { name: 'Cooperativa', value: 'Cooperativa' },
    { name: 'Distribuidor', value: 'Distribuidor' },
    { name: 'Outro', value: 'Outro' },
  ];

  const sistemasIns = [
    { name: 'SIM/POA', value: 'SIM/POA' },
    { name: 'SISBI/POA', value: 'SISBI/POA' },
    { name: 'SIF', value: 'SIF' },
  ];

  async function cadastrar(values: CadastroIntegrador): Promise<void> {
    store.toggleLoader();

    const coords = {
      lat: Number(values.coordenadas.split(',')[0]),
      lon: Number(values.coordenadas.split(',')[1]),
    };

    const dadosFrigorifico = {
      nomeFantasia: values.nomeFantasia,
      razaoSocial: values.razaoSocial,
      username: values.cnpj,
      cpfCnpj: values.cnpj,
      sistemaInspecao: values.sistemaIns,
      estado: values.estado,
      cidade: values.cidade,
      coordenadas: `POINT(${coords.lon} ${coords.lat})`,
      quantidadeAbate: values.quantidadeAbate,
      tipo: values.tipo,
    };

    const dadosResponsavel = {
      username: values.cpfResp,
      password: values.senha,
      firstName: values.nomeResp.split(' ')[0],
      lastName: values.nomeResp.split(' ').slice(1).join(' '),
      email: values.emailResp,
      cpfCnpj: values.cpfResp,
    };

    await api
      .post('/user/', dadosResponsavel)
      .then(async () => {
        await api
          .post('/token/obtain/', {
            username: dadosResponsavel.username,
            password: dadosResponsavel.password,
          })
          .then(async (tRes) => {
            setToken(tRes.data);
            initInterceptors();

            await getCSRFToken();

            await api
              .post('/frigorifico/', dadosFrigorifico)
              .then(async () => {
                store.toggleLoader();
                props.handleClose();

                store.notificar('success', 'Cadastro realizado com sucesso!');
                await api
                  .get('/user/me/')
                  .then((meRes) => {
                    store.setLogado(meRes.data);
                    store.setLogado(meRes.data);
                  })
                  .catch(() => {
                    logout();
                  });
              })
              .catch(() => {
                store.notificar('error', 'Ocorreu um erro no cadastro do frigorífico.');
                store.toggleLoader();
              });
          })
          .catch(() => {
            store.notificar('error', 'Ocorreu um erro na obtenção do token.');
            store.toggleLoader();
          });
      })
      .catch(() => {
        store.notificar('error', 'Ocorreu um erro no cadastro do usuário responsável.');
        store.toggleLoader();
      });
  }

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <ModalBox>
          <IconButton onClick={props.handleClose} className="closeBtn">
            <CloseIcon />
          </IconButton>

          <ModalTitle>Faça o cadastro da empresa</ModalTitle>

          <Formik
            initialValues={{
              cnpj: '',
              razaoSocial: '',
              nomeFantasia: '',
              tipo: '',
              estado: '',
              cidade: '',
              coordenadas: '',
              quantidadeAbate: 0,
              sistemaIns: '',

              nomeResp: '',
              cpfResp: '',
              whatsappResp: '',
              telefoneResp: '',
              emailResp: '',
              senha: '',

              termos: false,
            }}
            validate={(values) => {
              const errors: { [key: string]: string } = {};

              if (!Validator.validarCadastro(values.cnpj)) {
                errors.cnpj = 'Insira um CNPJ válido';
              } else if (!values.razaoSocial) {
                errors.razaoSocial = 'Campo obrigatório';
              } else if (!values.nomeFantasia) {
                errors.nomeFantasia = 'Campo obrigatório';
              } else if (!values.tipo) {
                errors.tipo = 'Selecione o tipo de conta';
              } else if (!values.quantidadeAbate) {
                errors.quantidadeAbate = 'Insira a quantidade';
              } else if (!values.sistemaIns) {
                errors.sistemaIns = 'Selecione o sistema de inspeção';
              } else if (!Validator.validarCoordenadas(values.coordenadas)) {
                errors.coordenadas = "Insira as coordenadas no formato 'LAT, LON'";
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                state.cadastro = values;

                modalFinalizar.open();
                setSubmitting(false);
              }, 400);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, isSubmitting, setFieldValue }) => (
              <Form>
                <Scroll>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ReactInputMask
                        onChange={(e) => {
                          e.target.value = Formatter.getNumeros(e.target.value);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        mask={'99.999.999/9999-99'}
                        maskChar=""
                      >
                        {/* @ts-ignore */}
                        {() => (
                          <TextField
                            name="cnpj"
                            error={touched.cnpj && !!errors.cnpj}
                            helperText={touched.cnpj && (errors.cnpj || '')}
                            fullWidth
                            variant="outlined"
                            label="CNPJ"
                          />
                        )}
                      </ReactInputMask>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        name="razaoSocial"
                        error={touched.razaoSocial && !!errors.razaoSocial}
                        helperText={touched.razaoSocial && (errors.razaoSocial || '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        variant="outlined"
                        label="Razão Social"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        name="nomeFantasia"
                        error={touched.nomeFantasia && !!errors.nomeFantasia}
                        helperText={touched.nomeFantasia && (errors.nomeFantasia || '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        variant="outlined"
                        label="Nome Fantasia"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Select
                        id="tipo"
                        defaultValue=""
                        name="tipo"
                        error={touched.tipo && !!errors.tipo}
                        helperText={touched.tipo ? errors.tipo || '' : undefined}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Tipo de estabelecimento"
                        options={tipos}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <NumericInput
                        variant="outlined"
                        name="quantidadeAbate"
                        error={touched.quantidadeAbate && !!errors.quantidadeAbate}
                        helperText={touched.quantidadeAbate ? errors.quantidadeAbate || '' : undefined}
                        precision="0"
                        decimalSeparator=","
                        thousandSeparator="."
                        label="Quantidade Abate (ton/dia)"
                        onChange={(value) => setFieldValue('quantidadeAbate', value)}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Select
                        id="sistemaIns"
                        defaultValue=""
                        name="sistemaIns"
                        error={touched.sistemaIns && !!errors.sistemaIns}
                        helperText={touched.sistemaIns ? errors.sistemaIns || '' : undefined}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Sistema Inspeção"
                        options={sistemasIns}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <InputEndereco
                        value={values.coordenadas}
                        name="coordenadas"
                        error={touched.coordenadas && !!errors.coordenadas}
                        helperText={touched.coordenadas ? errors.coordenadas || '' : undefined}
                        onBlur={handleBlur}
                        onChange={(coords, cidade, estado) => {
                          setFieldValue('coordenadas', `${coords.lat}, ${coords.lon}`);
                          setFieldValue('cidade', cidade);
                          setFieldValue('estado', estado);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Scroll>

                <Button variant="contained" color="primary" disabled={isSubmitting} type="submit" fullWidth>
                  Cadastrar
                </Button>
              </Form>
            )}
          </Formik>

          {modalFinalizar.state ? (
            <FinalizarCadastro
              open={modalFinalizar.state}
              handleClose={modalFinalizar.close}
              state={state.cadastro!}
              onConfirm={cadastrar}
            />
          ) : null}
        </ModalBox>
      </Fade>
    </Modal>
  );
};

export default observer(Cadastro);
