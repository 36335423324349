import styled, { css } from 'styled-components';
import { GeolocateControl, FullscreenControl, NavigationControl, Popup as PopupMapbox } from 'react-map-gl';
import { transparentize } from 'polished';

export const GeolocateButton = styled(GeolocateControl)`
  margin-bottom: 15px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2) !important;

  position: absolute;
  top: 155px;
  right: 10px;
`;

export const FullscreenButton = styled(FullscreenControl)`
  margin-top: 15px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2) !important;
  position: relative !important;
`;

export const NavigationButtons = styled(NavigationControl)`
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2) !important;
  position: relative !important;
`;

export const FrigoPin = styled.div`
  position: relative;
  transform: translate(-50%, -100%);
  color: ${(props) => props.theme.palette.primary.main};

  width: 60px;
  height: 60px;

  svg {
    width: 60px;
    height: 60px;
  }
`;

export const Pin = styled.div`
  cursor: pointer;
  position: relative;
  height: 42px;
  width: 42px;

  background-color: ${(props) => props.color};

  border-radius: 50%;

  transform: translate(-20px, -20px);

  display: grid;
  place-items: center;

  border: 3px solid #d7dbff;

  span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.346154px;

    color: #ffffff;
  }

  &:before {
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    border: 10px solid ${(props) => transparentize(0.5, props.color ?? '')};
    z-index: -1;
    border-radius: 50%;
  }
`;

type PopupProps = {
  open?: boolean;
};
export const Popup = styled(PopupMapbox)<PopupProps>`
  cursor: default;
  width: 340px;

  transition: opacity 0.3s;

  opacity: 0;
  pointer-events: none;

  ${(props) =>
    props.open &&
    css`
      opacity: 1;
      pointer-events: all;
    `}

  border-radius: 10px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;

  letter-spacing: 1.2775px;

  color: #515151;

  .mapboxgl-popup-content {
    box-shadow: 0px 0px 24px rgba(201, 189, 255, 0.5);
  }

  .mapboxgl-popup-close-button {
    padding: 10px;
    border-radius: 3px;
  }

  p {
    margin: 6px 0;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.253846px;

    color: #515151;
  }
`;

export const PopupDetails = styled.div`
  p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;

    letter-spacing: 0.253846px;

    color: #c6c6c6;
  }

  h3 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.346154px;

    color: #515151;
  }
`;
