import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react-lite';

import useModal from '../../services/hooks/useModal';
import MapPicker from '../MapPicker';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import Axios from 'axios';
import useStore from '../../services/hooks/useStore';
import { DebounceInput } from 'react-debounce-input';
import Validator from '../../services/Validator';

type Props = {
  value: string;
  name?: string;
  error?: boolean;
  helperText?: string;
  onChange(coords: Coordenadas, cidade: string, estado: string): void;
  onBlur?(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void;
};
const InputEndereco: React.FC<Props> = (props) => {
  const store = useStore();
  const state = useLocalObservable(() => ({
    value: props.value,
    selectedCoord: {
      lon: 0,
      lat: 0,
    },
    estado: '',
    cidade: '',

    getCoordsByString(coords: Coordenadas) {
      const arr = state.value.split(',');
      if (arr.length < 2 || isNaN(Number(arr[0])) || isNaN(Number(arr[1]))) {
        return { lat: -24.955663168420294, lon: -53.45675692523501 }; // Retorno padrão
      }
      coords.lat = Number(arr[0]);
      coords.lon = Number(arr[1]);

      return coords;
    },

    async handleEndereco(coords: Coordenadas) {
      store.toggleLoader();

      await Axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${coords.lon},${coords.lat}.json`, {
        params: {
          access_token: store.mapboxToken,
          autocomplete: true,
          cachebuster: new Date().getTime(),
          language: 'pt',
        },
      }).then((res) => {
        const cidades = res.data.features.filter((feature: any) => feature.id.includes('place'));

        if (cidades.length) {
          state.cidade = cidades[0].text_pt;
          state.estado = cidades[0].context
            .filter((context: any) => context.id.includes('region'))[0]
            .short_code.slice(3);
        }
        store.toggleLoader();
      });
    },
  }));
  const modalMap = useModal();

  return (
    <>
      <FormControl error={props.error} fullWidth variant="outlined">
        <InputLabel htmlFor="endereco">Coordenadas</InputLabel>
        <DebounceInput
          element={OutlinedInput}
          id="endereco"
          label="Coordenadas"
          name={props.name}
          value={state.value}
          minLength={3}
          debounceTimeout={1000}
          onChange={async (e) => {
            state.value = e.target.value;

            const value = e.target.value.split(',');
            const coords = state.getCoordsByString({
              lat: Number(value[0]),
              lon: Number(value[1]),
            });

            if (Validator.validarCoordenadas(e.target.value)) {
              await state.handleEndereco(coords);
            }

            props.onChange(coords, state.cidade, state.estado);
          }}
          autoComplete="off"
          onBlur={props.onBlur}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={modalMap.open} edge="end">
                <LocationSearchingIcon />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />

        <FormHelperText>{props.helperText}</FormHelperText>
      </FormControl>

      {modalMap.state ? (
        <MapPicker
          open={modalMap.state}
          handleClose={modalMap.close}
          onConfirm={async (coords) => {
            await state.handleEndereco(coords);
            props.onChange(coords, state.cidade, state.estado);
            state.value = `${coords.lat}, ${coords.lon}`;
          }}
          defaultLocal={state.getCoordsByString(state.selectedCoord)}
        />
      ) : null}
    </>
  );
};

export default observer(InputEndereco);
