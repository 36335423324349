import { GridDirection, GridJustification } from '@material-ui/core';
import styled, { css } from 'styled-components';

type Props = {
  padding?: boolean;
};
export const Container = styled.div<Props>`
  width: calc(100vw - 70px);
  height: calc(100vh - 55px);
  position: absolute;
  right: 0;
  bottom: 0;

  overflow-y: auto;

  ${(props) =>
    props.padding &&
    css`
      padding: 15px;
    `}
`;

type FlexProps = {
  alignItems?: AlignSetting;
  justifyContent?: GridJustification;
  flexDirection?: GridDirection;
};
export const Flex = styled.div<FlexProps>`
  display: flex;

  align-items: ${(props) => (props.alignItems ? props.alignItems : 'start')};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'flex-start')};
  flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : 'row')};
`;

type GridProps = {
  alignItems?: AlignSetting;
  justifyContent?: GridJustification;
  flexDirection?: GridDirection;
  gridTemplateColumns?: string;
  gap?: string;
};
export const Grid = styled.div<GridProps>`
  display: grid;

  align-items: ${(props) => (props.alignItems ? props.alignItems : 'start')};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'flex-start')};
  flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : 'row')};
  grid-template-columns: ${(props) => (props.gridTemplateColumns ? props.gridTemplateColumns : '1fr')};
  gap: ${(props) => (props.gap ? props.gap : '0')};
`;

export const Content = styled.div`
  overflow-y: auto;
  padding: 10px 15px;
  max-height: calc(100% - 60px);
  margin-top: 60px;
`;
