import { makeAutoObservable } from 'mobx';
import Formatter from '../Formatter';
// import { api } from '../API';

class Especies {
  constructor() {
    makeAutoObservable(this);
  }

  arr: GenericType[] = [];

  async populate(): Promise<void> {
    // await api.get('/fazenda').then((res) => {
    //   this.arr = res.data.results;
    // });

    this.arr = [
      {
        name: 'Camarão',
        id: 'Camarão',
      },
      {
        name: 'Carpa',
        id: 'Carpa',
      },
      {
        name: 'Curimatá',
        id: 'Curimatá',
      },
      {
        name: 'Dourado',
        id: 'Dourado',
      },
      {
        name: 'Jatuarana',
        id: 'Jatuarana',
      },
      {
        name: 'Matrinchã',
        id: 'Matrinchã',
      },
      {
        name: 'Pacu (Caranha e outros)',
        id: 'Pacu (Caranha e outros)',
      },
      {
        name: 'Pangasius',
        id: 'Pangasius',
      },
      {
        name: 'Piau Açu',
        id: 'Piau Açu',
      },
      {
        name: 'Pirarucu',
        id: 'Pirarucu',
      },
      {
        name: 'Surubim',
        id: 'Surubim',
      },
      {
        name: 'Tambacu',
        id: 'Tambacu',
      },
      {
        name: 'Tambaqui',
        id: 'Tambaqui',
      },
      {
        name: 'Tambatinga',
        id: 'Tambatinga',
      },
      {
        name: 'Tilápia',
        id: 'Tilápia',
      },
      {
        name: 'Tucunaré',
        id: 'Tucunaré',
      },
      {
        name: 'Outro',
        id: 'Outro',
      },
    ];
  }

  getSelectOptions(): SelectRow[] {
    return Formatter.getSelectRows(this.arr, 'name', 'id');
  }
}

export default Especies;
