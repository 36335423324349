import { makeAutoObservable } from 'mobx';
import { api } from '../API';

export interface Configs {
  id?: string;
  pmPadrao: number;
  txFunrural: number;
  especiePadrao: string;
}

class FrigoConfigs {
  constructor() {
    makeAutoObservable(this);
  }

  values: Configs | null = null;

  async populate(): Promise<void> {
    const configs = await api.get('/config_parametro/');
    this.values = configs.data[0];
  }
}

export default FrigoConfigs;
