import { Grid } from '@mui/material';
import { useState } from 'react';
import { FiltroAvancadoProps } from './index';
import useStore from '../../services/hooks/useStore';
import AsyncSearchInput from '../Forms/AsyncSearchInput';
import AsyncSearchInputLote from '../Forms/AsyncSearchInputLote';

const FiltroAvancadoPrevisoesImportados: React.FC<FiltroAvancadoProps> = (props) => {
  const store = useStore();
  const [produtor, setProdutor] = useState({
    name: '',
    id: '',
    key: '',

  });
  const [lote, setLote] = useState({
    name: '',
    id: '',
  })
  const [safra, setSafra] = useState({
    name: '',
    id: '',
  })


  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AsyncSearchInput
          url="/producer/"
          name="AquabitId"
          label="Selecione o Produtor e Propriedade"
          inscricao_federal={store.usuario!.cpfCnpj}
          integration={true}
          defaultValue={
            produtor
              ? {
                name: produtor.name,
                id: produtor.id,
              }
              : undefined
          }

          onChange={(e, value: any) => {
            setProdutor({
              name: value.name,
              id: value.aquabitId,
              key: value.aquabitId,
            })


            props.handleSelectAsyncChangeProdutor({
              name: value.name,
              id: value.id,
              key: value.aquabitId,
            });
          }}
        />
      </Grid>

      <Grid item lg={12}>
        <AsyncSearchInputLote
          url={`/lotes/`}
          name="lote"
          prop={produtor.id}
          integration={true}
          label="Selecione o lote"
          disabled={produtor.id == ""}
          lote={true}
          defaultValue={
            lote
              ? {
                codigo: lote.name,
                id: lote.id,
              }
              : undefined

          }

          onChange={(_e, value: any) => {
      
            setLote({
              id: value.id,
              name: value.lote as string
            })
            props.handleSelectAsyncChangeLote({
              id: value.id,
              name: value.lote as string,
              key: value.id,
            });

          }}
        />
      </Grid>


      <Grid item lg={12}>
        <AsyncSearchInputLote
          url={`/safras/`}
          name="safra"
          prop={safra.name}
          integration={false}
          label="Selecione a Safra"
          onChange={(_e, value) => {
            props.handleSelectAsyncChangeSafra({
              name: value!.name,
              id: value!.id,
              key: value!.id,
            });


            setSafra({
              name: value!.name,
              id: value!.id
            })

          }}
        />
      </Grid>


    </Grid>
  );
};




export default FiltroAvancadoPrevisoesImportados;