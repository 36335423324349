import Backdrop from '@mui/material/Backdrop';
import { ModalBox, Modal, ModalTitle, Scroll } from './styles';
import {
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import useModal from '../../services/hooks/useModal';
import FormProdutor from './FormProdutor';
import { Form, Formik } from 'formik';
import useStore from '../../services/hooks/useStore';
import { api } from '../../services/API';
import { observer } from 'mobx-react-lite';
import CloseIcon from '@mui/icons-material/Close';
import AsyncSearchInput from '../Forms/AsyncSearchInput';
import React, { useState } from 'react';
import Previsao from '../../services/models/Previsao';
import AsyncSearchInputLote from '../Forms/AsyncSearchInputLote';
import FormSafra from './FormSafra';
import Formatter from '../../services/Formatter';

type Props = ModalProps & {
  onSuccess(): void;
  previsao: Previsao | null;
};

const useStyles = makeStyles({
  div: {
    width: '530px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.12)',
    marginBottom: '1rem',
    borderRadius: '.4rem',
    transition: 'all 0.4s',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.4)',
    },
  },
});

const FormPrevisao: React.FC<Props> = ({ open, handleClose, onSuccess, previsao }) => {
  const classes = useStyles();
  const theme = useTheme();
  const modalProdutor = useModal();
  const modalSafra = useModal();
  const store = useStore();
  const [tanque, setTanque] = useState([]);
  const [checked, setChecked] = useState([] as any);

  const handleCheck = (event: { target: { checked: any; value: any } }) => {
    if (event.target.checked) {
      setChecked((oldChecked: any) => [...oldChecked, ...tanque]);
    } else {
      setChecked([]);
    }
  };

  async function cadastrarTanque(id: string): Promise<void> {
    store.toggleLoader();

    const checkedProps = checked.map((res: any) => {
      return {
        id: res.id,
        fase_lote: res.faseLote,
        quantidade: res.quantidade,
        lote_id: res.loteId,
        lote_nome: res.loteNome || null,
        lote_codigo: res.loteCodigo,
        tanque_id: res.tanqueId,
        tanque_nome: res.tanqueNome,
        tanque_codigo: res.tanqueCodigo,
        data_alojamento: res.dataAlojamento,
        propriedade_id: res.propriedadeId,
        peso_medio: res.pesoMedio,
        especie: res.especie,
        safra_id: id,
      };
    });

    await api
      .post('/integration/lote-tanques/create/', checkedProps)

      .then(() => {
        store.notificar('success', 'Tanques cadastrado com sucesso!');
        onSuccess();

        handleClose();
      })
      .catch(() => {
        store.notificar('error', 'Ocorreu ao cadastrar os Tanques!');
      });

    store.toggleLoader();
  }
  async function cadastrar(values: CadastroPrevisao): Promise<void> {
    store.toggleLoader();
    const data = {
      fazenda: values.fazenda,
      dataPovoamento: values.dataPovoamento?.toISOString(),
      loteTanque: {
        lote: values.lote,
        tanques: values.tanques,
      },
      especie: values.especie,
      quantidadeInicial: values.quantidadeInicial,
      pmEsperado: values.pmEsperado * 1000,
      pmInicial: values.pmInicial * 1000,
      status: values.status,
      safra: values.safra,
      biomassaEsperada: Number((values.biomassaEsperada * 1000).toFixed(3)),
      dtUltimoPm: values.dataPovoamento?.toISOString(),
      observacao: values.observacao,
    };

    await api
      .post('/previsao/', data)
      .then(() => {
        store.notificar('success', 'Previsão cadastrada com sucesso!');
        onSuccess();

        handleClose();
      })
      .catch(() => {
        store.notificar('error', 'Ocorreu ao cadastrar a previsão!');
      });

    store.toggleLoader();
  }

  async function editar(values: CadastroPrevisao): Promise<void> {
    store.toggleLoader();
    const data = {
      fazenda: values.fazenda,
      dataPovoamento: values.dataPovoamento?.toISOString(),
      loteTanque: {
        lote: values.lote,
        tanques: values.tanques,
      },
      especie: values.especie,
      quantidadeInicial: values.quantidadeInicial,
      pmEsperado: values.pmEsperado * 1000,
      pmInicial: values.pmInicial * 1000,
      status: values.status,
      safra: values.safra,
      biomassaEsperada: Number((values.biomassaEsperada * 1000).toFixed(3)),
      dtUltimoPm: values.dataPovoamento?.toISOString(),
      observacao: values.observacao,
    };

    await api
      .patch('/previsao/' + previsao!.id + '/', data)
      .then(() => {
        store.notificar('success', 'Previsão editada com sucesso!');
        onSuccess();

        handleClose();
      })
      .catch(() => {
        store.notificar('error', 'Ocorreu ao editar a previsão!');
      });

    store.toggleLoader();
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <ModalBox style={{ maxWidth: '600px' }}>
          <IconButton onClick={handleClose} className="closeBtn">
            <CloseIcon />
          </IconButton>
          <ModalTitle>Importar Lotes e Tanques</ModalTitle>
          <br />
          <Formik
            initialValues={{
              producer: '',
              aquabitId: '',
              lote: '',
              loteId: '',
            }}
            validate={(values) => {
              const errors: { [key: string]: string } = {};
              if (!values.producer) {
                errors.fazenda = 'Informe o produtor/propriedade';
              } else if (!values.lote) {
                errors.lote = 'Informe o lote';
              }
              return errors;
            }}
            onSubmit={(values) => {
              setTimeout(() => {
                if (previsao) {
                  editar(values as any);
                } else {
                  cadastrar(values as any);
                }
              }, 400);
            }}
          >
            {({ values, errors, touched, handleBlur, setFieldValue }) => (
              <Form style={{ width: '100%' }}>
                <Scroll>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <AsyncSearchInput
                        url="/producer/"
                        name="AquabitId"
                        inscricao_federal={store.usuario!.cpfCnpj}
                        integration={true}
                        label="Selecione o Produtor e Propriedade"
                        defaultValue={
                          values.producer
                            ? {
                                name: values.producer,
                                id: values.aquabitId,
                              }
                            : undefined
                        }
                        error={touched.producer && !!errors.producer}
                        helperText={touched.producer ? (errors.producer as string) || '' : undefined}
                        onChange={(_e, value) => {
                          setFieldValue('producer', value!.name);
                          setFieldValue('aquabitId', value!.aquabitId);
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item style={{ alignSelf: 'center' }} xs={2}>
                      <IconButton onClick={modalProdutor.open} style={{ height: 48 }}>
                        <AddIcon />
                      </IconButton>
                    </Grid>

                    <Grid item lg={12}>
                      <AsyncSearchInputLote
                        url={`/lotes/`}
                        name="lote"
                        prop={values.aquabitId}
                        integration={true}
                        label="Selecione um Lote"
                        disabled={values.aquabitId == ''}
                        lote={true}
                        defaultValue={{
                          codigo: values.lote,
                          id: values.loteId,
                        }}
                        onChange={async (_e, value) => {
                          setTanque([]);

                          await api
                            .get(`/integration/lote-tanques/`, {
                              params: {
                                propriedade: values.aquabitId,
                                lote: value!.id,
                              },
                            })
                            .then((res: any) => {
                              setTanque(res.data.results as []);
                            });
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item lg={12}></Grid>
                    {tanque.length > 0 ? (
                      <Grid item lg={12} style={{ overflowY: 'auto', height: '400px' }}>
                        <FormControlLabel
                          labelPlacement="start"
                          control={<Checkbox onChange={(e) => handleCheck(e)} tabIndex={-1} />}
                          label="Marcar todos os tanques"
                        />
                        <List style={{ width: '100%', maxWidth: 360 }}>
                          {tanque.map((item: any) => {
                            const selected = checked.some((obj: any) => item.id === obj.id);
                            return (
                              <ListItem
                                key={item.id}
                                className={classes.div}
                                style={{
                                  boxShadow: selected
                                    ? '0px 0px 15px rgba(17, 148, 39, 0.836)'
                                    : '0px 0px 15px rgba(0, 0, 0, 0.12)',
                                }}
                                onClick={() => {
                                  setChecked([]);
                                  setChecked((oldChecked: any) => [...oldChecked, item]);
                                  modalSafra.open();
                                }}
                              >
                                <ListItemText
                                  style={{ width: '300px', fontSize: '5px' }}
                                  primary={`${item.tanqueNome || item.tanqueCodigo} - (${item.especie})`}
                                  secondary={`Data alojamento: ${item.dataAlojamento}`}
                                />
                                <ListItemText
                                  style={{ textAlign: 'right', width: '300px' }}
                                  primary={`Qt: ${Formatter.formatNumber(0, item.quantidade)}`}
                                  secondary={`PM Inicial (Kg): ${Formatter.formatNumber(3, item.pesoMedio / 1000)}`}
                                />
                              </ListItem>
                            );
                          })}
                        </List>
                      </Grid>
                    ) : (
                      <Grid item lg={12} style={{ overflowY: 'auto', height: '400px', textAlign: 'center' }}>
                        <p>Nenhum Tanque Selecionando</p>
                      </Grid>
                    )}
                  </Grid>
                </Scroll>

                <Button
                  type="submit"
                  fullWidth
                  style={{
                    backgroundColor: theme.palette.primary.main, //TODO: change to variant color = primary
                    color: '#FFF',
                    height: 40,
                  }}
                  variant="contained"
                  onClick={modalSafra.open}
                >
                  IMPORTAR
                </Button>

                {modalProdutor.state ? (
                  <FormProdutor
                    open={modalProdutor.state}
                    handleClose={modalProdutor.close}
                    onConfirm={(fazenda) => {
                      setTimeout(() => {
                        setFieldValue('fazenda', fazenda.id);
                      }, 1000);
                    }}
                  />
                ) : null}
                {modalSafra.state ? (
                  <FormSafra open={modalSafra.state} handleClose={modalSafra.close} cadastrarTanque={cadastrarTanque} />
                ) : null}
              </Form>
            )}
          </Formik>
        </ModalBox>
      </Fade>
    </Modal>
  );
};

export default observer(FormPrevisao);
