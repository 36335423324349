import styled from 'styled-components';

const Header = styled.div`
  width: inherit;
  height: 60px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  box-shadow: 0px 3px 4px 0px #0000002e;
  position: fixed;
`;

export default Header;
