import { TextField } from '@mui/material';
import { useState } from 'react';
import ReactInputMask from 'react-input-mask';
import Formatter from '../../services/Formatter';

type Props = {
  value?: string;
  name?: string;
  error?: boolean;
  helperText?: string;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  onBlur?(e: React.FocusEvent<HTMLInputElement>): void;
};

const CPFCNPJInput: React.FC<Props> = (props) => {
  const masks = {
    cpf: '999.999.999-99',
    cnpj: '99.999.999/9999-99',
  };
  const [mask, setMask] = useState(props.value ? (props.value.length === 14 ? masks.cnpj : masks.cpf) : masks.cpf);
  const [value, setValue] = useState('');

  return (
    <ReactInputMask
      value={props.value}
      onPaste={(e) => {
        const pastedValue = Formatter.getNumeros(e.clipboardData.getData('text/plain'));

        if (pastedValue.length > 11) {
          setMask(masks.cnpj);
        }
      }}
      onChange={(e) => {
        const newValue = Formatter.getNumeros(e.target.value);

        setValue(newValue);
        e.target.value = newValue;
        props.onChange(e);
      }}
      onBlur={props.onBlur}
      mask={mask}
      maskChar=""
    >
      {/* @ts-ignore */}
      {() => (
        <TextField
          name={props.name}
          error={props.error}
          helperText={props.helperText}
          fullWidth
          variant="outlined"
          label="CPF/CNPJ"
          onKeyDown={(e) => {
            // Digitando
            if (isFinite(Number(e.key))) {
              if (value.length >= 11) {
                setMask(masks.cnpj);
              } else {
                setMask(masks.cpf);
              }
            }

            // Apagando
            if (e.keyCode === 8 && value.length === 12) {
              e.preventDefault();
              setMask(masks.cpf);
              setValue(Formatter.formatCPFCNPJ(value.slice(0, -1)));
            }
          }}
        />
      )}
    </ReactInputMask>
  );
};

export default CPFCNPJInput;
