import { Backdrop, CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';

import styled from 'styled-components';
import useStore from '../services/hooks/useStore';
import { Flex } from './Container';

const Loader: React.FC = () => {
  const store = useStore();

  return (
    <StyledBackdrop open={store.loader}>
      <Flex style={{ flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
        <br />
        {!store.logado ? (
          <p style={{ color: 'white' }}>Validando credenciais</p>
        ) : (
          <p style={{ color: 'white' }}>Carregando dados</p>
        )}
      </Flex>
    </StyledBackdrop>
  );
};

export default observer(Loader);

const StyledBackdrop = styled(Backdrop)`
  z-index: 100000000 !important;
  svg {
    color: white;
  }
`;
