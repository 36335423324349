import { Backdrop, Button, Fade, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { ModalBox, Modal } from '../Modais/styles';
import ReactMapGL, { Marker, ViewState } from 'react-map-gl';
import useStore from '../../services/hooks/useStore';
import { observer } from 'mobx-react-lite';

type Props = ModalProps & {
  defaultLocal: Coordenadas;
  onConfirm(coords: Coordenadas): void;
};

const MapPicker: React.FC<Props> = (props) => {
  console.log(props);
  const store = useStore();
  const theme = useTheme();
  // @todo: fix this viewport type with ViewState after finish the update
  const [mapState, setMapState] = useState<{ viewport: ViewState }>({
    viewport: {
      latitude: props.defaultLocal.lat,
      longitude: props.defaultLocal.lon,
      zoom: 10,
      bearing: 0,
      pitch: 0,
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
  });
  const [local, setLocal] = useState({
    lat: props.defaultLocal.lat,
    lon: props.defaultLocal.lon,
  });

  function confirmar(): void {
    const coords = {
      lat: local.lat,
      lon: local.lon,
    };
    props.onConfirm(coords);
    props.handleClose();
  }

  useEffect(() => {
    console.log(JSON.stringify(store.mapboxToken));
  }, [local]);
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <ModalBox width="80%" style={{ padding: 0, height: 600 }}>
          <ReactMapGL
            {...mapState.viewport}
            onClick={(e: any) => {
              if (e && e.lngLat && e.lngLat.lng !== undefined && e.lngLat.lat !== undefined) {
                setLocal({
                  lon: e.lngLat.lng,
                  lat: e.lngLat.lat,
                });
              }
            }}
            mapStyle="mapbox://styles/gabspereiradev/cl7saqm3e001a16ma5t0zvmkz"
            mapboxAccessToken={store.mapboxToken}
            // @todo: fix this viewport type with ViewState after finish the update
            onMove={(evt) => {
              setMapState({ viewport: evt.viewState });
            }}
            onLoad={(e: any) => {
              const map = e?.target;
              if (map && typeof map.setLayoutProperty === 'function') {
                map.setLayoutProperty('country-label', 'text-field', ['get', 'name_pt']);
              }
            }}
          >
            <div
              style={{
                backgroundImage: 'linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,1) 40%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                position: 'absolute',
                padding: '25px 0 50px 0',
              }}
            >
              <h1>Confirme a localização</h1>
            </div>

            <Marker longitude={local.lon} latitude={local.lat}>
              <img
                src="/picker.svg"
                style={{
                  position: 'relative',
                  width: 40,
                  height: 44.45,
                  transform: 'translate(-50%, -100%)',
                }}
                alt="Marcador"
              />
            </Marker>

            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                style={{
                  position: 'absolute',
                  bottom: 30,
                  width: '45%',
                  border: 'none',
                  backgroundColor: theme.palette.primary.main, //TODO: change to variant color = primary
                  color: '#FFF',
                  height: 38,
                }}
                onClick={confirmar}
              >
                Confirmar
              </Button>
            </div>
          </ReactMapGL>
        </ModalBox>
      </Fade>
    </Modal>
  );
};

export default observer(MapPicker);
