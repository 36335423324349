import styled from 'styled-components';
import { Modal as ModalControl } from '@mui/material';
import { Link as LinkBase } from 'react-router-dom';

export const Modal = styled(ModalControl)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ModalBoxProps {
  width?: string;
}
export const ModalBox = styled.div<ModalBoxProps>`
  position: relative;
  background-color: white;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.25);
  border-radius: 7px;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 25px 20px;
  width: ${(props) => props.width || '90%'};
  max-width: ${(props) => props.width || '450px'};
  min-height: 200px;

  &:focus {
    outline: none;
  }

  .closeBtn {
    position: absolute;
    top: 15px;
    right: 15px;
  }
`;

export const ModalTitle = styled.h2`
  color: ${(props) => props.theme.palette.primary.main};

  width: 100%;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;
`;

export const Scroll = styled.div`
  max-height: calc(100vh - 150px);
  padding: 15px 10px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10px;

  /* width */
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const Link = styled(LinkBase)`
  color: ${(props) => props.theme.palette.primary.main};
  text-decoration: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
`;

export const Dado = styled.p`
  font-family: 'Lato';
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  color: #949494;
`;
