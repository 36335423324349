import { lazy, Suspense } from 'react';
import Drawer from '../components/Drawer';
import NavBar from '../components/NavBar';
import Home from '../pages/Home';
const Agenda = lazy(() => import('../pages/Agenda'));
const CalendarioTotais = lazy(() => import('../pages/Previsoes/CalendarioTotais'));
const Previsoes = lazy(() => import('../pages/Previsoes'));
const Avaliacoes = lazy(() => import('../pages/Avaliacoes'));
const Propriedades = lazy(() => import('../pages/Propriedades'));
const Romaneios = lazy(() => import('../pages/Romaneios'));
const Categorias = lazy(() => import('../pages/Pagamentos/Categorias'));
const Pagamentos = lazy(() => import('../pages/Pagamentos'));
const Recolhimentos = lazy(() => import('../pages/Pagamentos/Recolhimentos'));
const ParametrosGerais = lazy(() => import('../pages/ParametrosGerais'));
const RTDespescaPorPrevisao = lazy(() => import('../pages/Relatórios/RTDespecaPorPrevisao'));

import useModal from '../services/hooks/useModal';
import Cadastro from '../components/Modais/Cadastro';
import Login from '../components/Modais/Login';
import { Route, Routes } from 'react-router-dom';
import Loader from '../components/Loader';
import IntegracaoPropriedades from '../pages/IntegracaoPropriedades';

const DesktopRoutes: React.FC = () => {
  const modalLogin = useModal();
  const modalCadastro = useModal();

  return (
    <>
      <NavBar modalLogin={modalLogin} modalCadastro={modalCadastro} />
      <div style={{ display: 'flex' }}>
        <Drawer />

        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/agenda" element={<Agenda />} />
            <Route path="/previsoes" element={<Previsoes />} />
            <Route path="/relatorios/mapa-despescas" element={<CalendarioTotais />} />
            <Route path="/relatorios/despesca-por-previsao" element={<RTDespescaPorPrevisao />} />
            <Route path="/avaliacoes" element={<Avaliacoes />} />
            <Route path="/propriedades" element={<Propriedades />} />
            <Route path="/categorias" element={<Categorias />} />
            <Route path="/configuracoes" element={<ParametrosGerais />} />
            <Route path="/romaneios" element={<Romaneios />} />
            <Route path="/pagamentos" element={<Pagamentos />} />
            <Route path="/recolhimentos" element={<Recolhimentos />} />
            <Route path="/integracao/:boolean?" element={<IntegracaoPropriedades />} />
          </Routes>
        </Suspense>

        <Login open={modalLogin.state} handleClose={modalLogin.close} />
        <Cadastro open={modalCadastro.state} handleClose={modalCadastro.close} />
      </div>
    </>
  );
};

export default DesktopRoutes;
