import { makeAutoObservable } from 'mobx';
import { api } from '../API';

export default class Frigorifico {
  constructor() {
    makeAutoObservable(this);
  }

  frigorifico: FrigorificoType | null = null;
  params: Parametro[] = [];

  get id(): string {
    return this.frigorifico!.id;
  }

  set(frigorifico: FrigorificoType): void {
    this.frigorifico = frigorifico;
    this.params = frigorifico.params || [];
  }

  isActiveParam(paramID: string): boolean {
    return this.params.filter((param) => param.id === paramID)[0].ativo;
  }

  async getAllParams(reqParams?: Record<string, any>): Promise<Parametro[]> {
    let params: Parametro[] = [];

    await api.get('/configuracao/default_parameters/', reqParams).then((res) => {
      function checkID(param: Parametro, paramID: string): boolean {
        return param.id === paramID;
      }

      params = res.data.map((param: Parametro) => {
        param.ativo = true;
        return param;
      });

      this.params.map((option) => {
        const index = params.findIndex((paramItem) => checkID(paramItem, option.id));

        if (index >= 0) {
          params[index].ativo = option.ativo;
        }

        return null;
      });
    });

    this.params = params;

    return params;
  }
}
