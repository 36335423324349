import { api, getCSRFToken, initInterceptors, setToken } from './API';
import { stateContext } from './hooks/useStore';

const store = stateContext.state;

export async function entrar(values: { username: string; password: string }): Promise<void> {
  try {
    const response = await api.post('/token/obtain/', values);

    setToken(response.data);
    initInterceptors();

    await getCSRFToken();

    await api.get('/user/me/').then((res) => {
      store.setLogado(res.data);
    });
  } catch (err: any) {
    if (err.response?.status === 401) {
      store.notificar('warning', 'Usuário ou senha incorretos!');
    } else {
      store.notificar('error', 'Ocorreu um erro ao validar credenciais!');
    }
    throw err;
  }
}
