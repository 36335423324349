import { makeAutoObservable } from 'mobx';

class FormasDePagamento {
  constructor() {
    makeAutoObservable(this);
  }

  arr: SelectRow[] = [
    {
      name: 'Dinheiro',
      value: 'MONEY',
    },
    {
      name: 'Boleto',
      value: 'BILLET',
    },
    {
      name: 'Transferência',
      value: 'TRANSFERENCE',
    },
    {
      name: 'Cheque',
      value: 'BANKCHEQUE',
    },
  ];

  situations: SelectRow[] = [
    { name: 'A Pagar', value: 'PAYABLE' },
    { name: 'Pago', value: 'PAID' },
  ];
}

export default FormasDePagamento;
