import { useCallback, useEffect } from 'react';
import { Marker } from 'react-map-gl';
import { Pin } from './styles';
import { api } from '../../services/API';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { Popup, PopupDetails } from './styles';
import { PopupButton } from '../Botao';
import { Grid } from '@mui/material';
import useStore from '../../services/hooks/useStore';
import Formatter from '../../services/Formatter';

interface MarkerFazenda {
  uuid: string;
  coordenadas: number[];
  totalLiberado: number;
  dataPrevista: string | null;
  especie: string;
  nome: string;
  produtor: string;
  tonEstimada: number;
}

const MarcadoresFazendas: React.FC = () => {
  const store = useStore();
  const state = useLocalObservable(() => ({
    fazendas: null as MarkerFazenda[] | null,
    setFazendas(fazendas: MarkerFazenda[]) {
      this.fazendas = fazendas;
    },

    popupCoords: null as Coordenadas | null,
    selectedFazenda: null as null | MarkerFazenda,
    setSelectedFazenda(fazenda: MarkerFazenda | null, coords: Coordenadas | null) {
      this.selectedFazenda = fazenda;
      this.popupCoords = coords;
    },
  }));

  const PopupInfo: React.FC = () => {
    return (
      <Popup
        // @todo: verify this maxWitdth instead of tipSize before submit to git
        // maxWidth="10px"
        anchor="top"
        longitude={state.popupCoords?.lon || 0}
        latitude={state.popupCoords?.lat || 0}
        closeOnClick={false}
        onClose={() => state.setSelectedFazenda(null, null)}
        open={!!state.selectedFazenda}
      >
        <h3>{state.selectedFazenda?.nome}</h3>
        <p>{state.selectedFazenda?.produtor}</p>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <PopupDetails>
              <p>Tonelada Estimada</p>
              <h3>
                {state.selectedFazenda ? Formatter.formatNumber(1, state.selectedFazenda.tonEstimada / 1000_000) : '-'}
              </h3>
            </PopupDetails>
          </Grid>

          <Grid item xs={6}>
            <PopupDetails>
              <p>Espécie</p>
              <h3>{state.selectedFazenda?.especie}</h3>
            </PopupDetails>
          </Grid>

          <Grid item xs={6}>
            <PopupDetails>
              <p>Previsão Despesca</p>
              <h3>
                {state.selectedFazenda?.dataPrevista
                  ? Formatter.formatDate(new Date(state.selectedFazenda.dataPrevista))
                  : '-/-/-'}
              </h3>
            </PopupDetails>
          </Grid>
          <Grid item xs={6}>
            <PopupButton>DETALHES</PopupButton>
          </Grid>
        </Grid>
      </Popup>
    );
  };

  const getFazendas = useCallback(async () => {
    await api
      .get('overview/mapa/')
      .then((res) => {
        state.setFazendas(res.data);
      })
      .catch(() => {
        store.notificar('error', 'Ocorreu um erro ao solicitar as fazendas! Tente novamente mais tarde.');
      });
  }, [store.logado, state.setFazendas]);

  useEffect(() => {
    getFazendas();
  }, [getFazendas]);

  return (
    <>
      {state.fazendas?.map((fazenda) => {
        const coords = {
          lon: fazenda.coordenadas[0],
          lat: fazenda.coordenadas[1],
        };
        const colors = ['#009606', '#C67700', '#A90303'];

        const color = fazenda.dataPrevista ? (fazenda.totalLiberado ? colors[0] : colors[1]) : colors[2];

        return (
          <Marker key={fazenda.uuid} longitude={coords.lon} latitude={coords.lat}>
            <Pin color={color} onClick={() => state.setSelectedFazenda(fazenda, coords)}>
              <span>{Formatter.formatNumber(0, fazenda.tonEstimada / 1000_000)}</span>
            </Pin>
          </Marker>
        );
      })}
      {state.popupCoords && <PopupInfo />}
    </>
  );
};

export default observer(MarcadoresFazendas);
