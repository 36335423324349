import { makeAutoObservable } from 'mobx';
import Formatter from '../Formatter';
// import { api } from '../API';

class Situacoes {
  constructor() {
    makeAutoObservable(this);
  }

  arr: SelectRow[] = [
    {
      name: 'Aguardando',
      value: 'AGUARDANDO',
    },
    {
      name: 'Liberado',
      value: 'LIBERADO',
    },
    {
      name: 'Em Despesca',
      value: 'EM_DESPESCA',
    },
    {
      name: 'Finalizado',
      value: 'FINALIZADO',
    },
  ];

  getSelectOptions(creating: boolean): SelectRow[] {
    if (creating) {
      const newArr = this.arr.slice(0, 2);
      return Formatter.getSelectRows(newArr, 'name', 'value');
    } else {
      return Formatter.getSelectRows(this.arr, 'name', 'value');
    }
  }
}

export default Situacoes;
