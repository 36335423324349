import { FormControl, FormHelperText, InputLabel, MenuItem, Select as MuiSelect,  } from '@mui/material';

// Types
type Props = {
  value?: string;
  defaultValue?: string | number;
  label?: string;
  options?: SelectRow[];
  helperText?: string;
  error?: boolean;
  name?: string;
  id?: string;
  disabled?: boolean;
  size?: 'small' | 'medium';
  onChange?:any;
  onFocus?(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  onBlur?(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void;
};

const Select: React.FC<Props> = (props) => {
  return (
    <FormControl fullWidth variant="outlined" size={props.size} error={props.error}>
      <InputLabel id={props.label}>{props.label}</InputLabel>
      <MuiSelect
        fullWidth
        id={props.id}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        value={props.value}
        defaultValue={props.defaultValue}
        labelId={props.label}
        label={props.label}
        name={props.name}
        disabled={props.disabled}
        margin={props.size === 'small' ? 'dense' : undefined}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            style: {
              maxHeight: 300,
              overflowY: 'auto',
            },
          },
          MenuListProps: {
            style: {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
            },
          },
        }}
      >
        <MenuItem
          data-cy="select-row"
          value=""
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            padding: '6px 16px',
          }}
        >
          <em>Nenhuma opção</em>
        </MenuItem>
        {props.options &&
          props.options.map((item) => {
            return (
              <MenuItem
                data-cy="select-row"
                key={item.value}
                value={item.value}
                style={{
                  width: '100%',
                  justifyContent: 'flex-start',
                  padding: '6px 16px',
                }}
              >
                {item.name}
              </MenuItem>
            );
          })}
      </MuiSelect>

      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
};

export default Select;
