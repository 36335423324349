import styled from 'styled-components';
import { MenuItem, TableHead } from '@mui/material';

export const Tabela = styled.div`
  overflow: hidden;
  border-radius: 3px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
`;

export const TabelaHead = styled(TableHead)`
  background-color: ${(props) => props.theme.palette.primary.main};

  th {
    color: white;
  }
`;

export const NomeFazenda = styled.h2`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 41px;
  letter-spacing: 0.846154px;

  color: #aeaeae;
`;

export const MenuItemAction = styled(MenuItem)`
  display: flex !important;
  padding: 6px 10px !important;
  justify-content: start !important;
  gap: 8px !important;
`;
