import App from './App';
import React from 'react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { createRoot } from 'react-dom/client';

async function init(): Promise<void> {
  const container = document.getElementById('root');

  if (!container) {
    throw new Error('Root container not found!');
  }

  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}

init();
serviceWorkerRegistration.register();
