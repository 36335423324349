import { Localization, Options } from 'material-table';

type TabelaConfig = {
  options: Options<any>;
  style: React.CSSProperties;
  localization: Localization;
  detailHeaderStyle: React.CSSProperties;
};

export const config: TabelaConfig = {  
  options: {
    toolbar: false,
    sorting: false,    
    maxBodyHeight: window.innerHeight - 240,
    minBodyHeight: window.innerHeight - 240,
    headerStyle: {
      background: '#00557F',
      color: 'white',
      height: '40px',
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: 14,
      borderBottom: 'none',      
    },    
    rowStyle: {
      fontSize: 14,            
    },
    pageSizeOptions: [],
    paginationType: 'stepped',
    tableLayout: 'fixed',            
  },
  style: {
    width: '100%',
    fontFamily: 'Roboto',    
  },
  localization: {
    body: {
      emptyDataSourceMessage: 'Ainda não existem registros cadastrados!',
    },
    toolbar: {
      exportAriaLabel: 'Exportar como CSV',
    },
    pagination: {
      firstTooltip: 'Primeira',
      previousTooltip: 'Anterior',
      nextTooltip: 'Próxima',
      lastTooltip: 'Última',
    },
    header: {
      actions: 'Ações',
    },
  },
  detailHeaderStyle: {
    background: 'white',
    color: '#2D2D2D',
    height: '40px',
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 14,
    borderBottom: 'none',
    zIndex: 0,
  },  
};

type NewTabelaConfig = {
  options: Options<any>;
  style: React.CSSProperties;
  localization: Localization;
};
export const newConfig: NewTabelaConfig = {
  options: {
    toolbar: false,
    maxBodyHeight: window.innerHeight - 200,
    minBodyHeight: window.innerHeight - 200,
    headerStyle: {
      color: '#828282',
      height: '40px',
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: 12,      
    },
    pageSizeOptions: [],
    pageSize: 50,
    tableLayout: 'fixed',
    paginationType: 'stepped',
  },
  style: {
    boxShadow: 'none',
    borderColor: 'white',
    fontSize: 14,   
  },
  localization: {
    body: {
      emptyDataSourceMessage: 'Ainda não existem registros cadastrados!',
    },
    toolbar: {
      exportCSVName: 'Baixar como CSV',
      exportPDFName: 'Baixar como PDF',
      exportTitle: 'Baixar',
      searchTooltip: 'Buscar',
      searchPlaceholder: 'Buscar',
    },
    pagination: {
      firstTooltip: 'Primeira',
      previousTooltip: 'Anterior',
      nextTooltip: 'Próxima',
      lastTooltip: 'Última',
    },
    header: {
      actions: 'Ações',
    },
  },
};