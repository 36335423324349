import { Button, Drawer, Grid, IconButton, useTheme } from '@mui/material';
import { useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ModalTitle } from '../Modais/styles';
import CloseIcon from '@mui/icons-material/Close';

export interface FiltroAvancadoProps {
  filtros: Record<string, any>;
  handleSelectAsyncChangeSafra(event: any): void;
  handleSelectAsyncChangeProdutor(event: any): void;
  handleSelectAsyncChangeLote(event: any): void;
  handleSelectAsyncChange(event: any): void;
  handleDateChangeInicio(event: any): void;
  handleDateChangeFinal(event: any): void;
  handleTextChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
  handleSelectChange(
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ): void;
}

interface Props {
  onUpdate(filtros: Record<string, any>): void;
  filtros: Record<string, any>;
  Content: React.FC<FiltroAvancadoProps>;
}

const FiltroAvancado: React.FC<Props> = ({ onUpdate, filtros, Content }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [filtrosState, setFiltros] = useState<Record<string, any>>(filtros);

  function handleTextChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const name = event.target.name;
    const value = event.target.value;

    setFiltros((prev) => ({ ...prev, [name]: value }));
  }

  function handleSelectChange(
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) {
    const name = event.target.name;
    const value = event.target.value;

    setFiltros((prev) => ({ ...prev, [name!]: value }));
  }

  function handleSelectAsyncChange(event: any) {
    const name = 'fazenda__name';
    const value = event.name;

    setFiltros((prev) => ({ ...prev, [name!]: value }));
  }
  function handleSelectAsyncChangeProdutor(event: any) {
    const name = 'produtor';
    const value = event.id;

    setFiltros((prev) => ({ ...prev, [name!]: value }));
  }
  function handleSelectAsyncChangeLote(event: any) {
    const name = 'lote';
    const value = event.id;

    setFiltros((prev) => ({ ...prev, [name!]: value }));
  }
  function handleSelectAsyncChangeSafra(event: any) {
    const name = 'safra';
    const value = event.id;

    setFiltros((prev) => ({ ...prev, [name!]: value }));
  }

  function handleDateChangeInicio(event: any) {
    const name = 'data_inicio';
    const day = new Date(event).getDate();
    const month = new Date(event).getMonth();
    const year = new Date(event).getFullYear();
    const value = `${day}-${month + 1}-${year}`;

    setFiltros((prev) => ({ ...prev, [name!]: value }));
  }

  function handleDateChangeFinal(event: any) {
    const name = 'data_final';
    const day = new Date(event).getDate();
    const month = new Date(event).getMonth();
    const year = new Date(event).getFullYear();
    const value = `${day}-${month + 1}-${year}`;

    setFiltros((prev) => ({ ...prev, [name!]: value }));
  }

  function filtrar(): void {
    onUpdate(filtrosState);
    setOpen(false);
  }

  function limparFiltro(): void {
    setFiltros({});
    onUpdate({});
    setOpen(false);
  }

  function toggleDrawer() {
    setOpen((prev) => !prev);
  }

  return (
    <>
      <IconButton onClick={toggleDrawer}>
        <FilterListIcon />
      </IconButton>
      <Drawer anchor="right" open={open} onClose={toggleDrawer}>
        <div style={{ padding: 16, width: 400 }}>
          <IconButton
            style={{
              position: 'absolute',
              top: 8,
              right: 15,
            }}
            onClick={toggleDrawer}
          >
            <CloseIcon />
          </IconButton>
          <ModalTitle style={{ marginBottom: 5 }}>Filtros Avançados</ModalTitle>
          <br />
          <Content
            filtros={filtrosState}
            handleTextChange={handleTextChange}
            handleSelectChange={handleSelectChange}
            handleSelectAsyncChange={handleSelectAsyncChange}
            handleDateChangeInicio={handleDateChangeInicio}
            handleDateChangeFinal={handleDateChangeFinal}
            handleSelectAsyncChangeProdutor={handleSelectAsyncChangeProdutor}
            handleSelectAsyncChangeLote={handleSelectAsyncChangeLote}
            handleSelectAsyncChangeSafra={handleSelectAsyncChangeSafra}
          />

          <Grid container spacing={2} mt={1}>
            <Grid item xs={6}>
              <Button
                onClick={limparFiltro}
                variant="contained"
                fullWidth
                style={{
                  backgroundColor: theme.palette.secondary.main,//TODO: change to variant color = secondary
                  color: '#FFF',
                  height: 40,
                }}
              >
                Limpar Filtro
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={filtrar}
                variant="contained"
                fullWidth
                style={{
                  backgroundColor: theme.palette.primary.main, //TODO: change to variant color = primary
                  color: '#FFF',
                  height: 40,
                }}
              >
                Filtrar
              </Button>
            </Grid>
          </Grid>
        </div>
      </Drawer>
    </>
  );
};

export default FiltroAvancado;
