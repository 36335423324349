import { makeAutoObservable } from 'mobx';
import { api } from '../API';
import Formatter from '../Formatter';

class Categorias {
  constructor() {
    makeAutoObservable(this);
  }

  arr: Categoria[] = [];

  async populate(): Promise<void> {
    await api
      .get('/categorias', {
        params: {
          page_size: 100000,
        },
      })
      .then((res) => {
        this.arr = res.data.results;
      });
  }

  tipos: SelectRow[] = [
    { name: 'Débito', value: 'DEBIT' },
    { name: 'Crédito', value: 'CREDIT' },
  ];

  get(id: string): Categoria {
    return this.arr.filter((categoria) => categoria.id === id)[0];
  }

  getSelectOptions(): SelectRow[] {
    return Formatter.getSelectRows(this.arr, 'name', 'id');
  }
}

export default Categorias;
